<template>
  <v-container fluid pa-3 style="min-height: 85vh;">
      <AppProfile></AppProfile>
  </v-container>
</template>

<script>
// COMPONENTS

import AppProfile from '@/components/Profile/AppProfile.vue';


// VUEX
import { mapGetters } from 'vuex';
import { mapState } from 'vuex'

export default {
    components: {
        
        AppProfile,
        
    },
    created() {
        window.scrollTo(0, 0);
    },
    mounted() {

    },
    computed: {
    ...mapGetters(["theme", "locale", "loadingUI", "isAuthenticated"]),

      
    },
}
</script>

