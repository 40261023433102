import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'ar': {
        public: {
            colleaguesTotalPoints: 'مجمموع نقاط الزملاء',
            company: 'الشركة',
            department: 'القسم',
            departmentPoints: 'نقاط القسم',
            rank: 'الترتيب',
            comparedToColleagues: 'مقارنة بالزملاء',
            participations: 'مشاركات',
            from: 'من',
            noNotifications: 'لا توجد إشعارات',
            selfassessment: 'بوابة الإستبيانات',
            points: 'النقاط',
            profile: 'الملف الشخصي',
            redeemPoints: 'استبدال النقاط الآن',
            pointsBoard: 'لوحة النقاط المكتسبة',
            socilaMedia: 'التواصل الإجتماعي',
            relatedLinks: 'روابط متعلقة',
            signout: 'تسجيل الخروج',
            lang: 'اللغة',
            ar: 'العربية',
            en: 'الإنجليزية',
            brandName: 'هناك حقيقة مثبتة',
            brandCategoryHeader: 'خصائص المؤسسة',
            brandCategoryDesc: `نص شكلي (بمعنى أن الغاية هي الشكل وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر, نص شكلي (بمعنى أن الغاية هي الشكل وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر.`,
            brandSite: 'الذهاب للموقع',
            brandAbout: 'هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء.',
            brandRights: 'جميع الحقوق محفوظة لـ  ',
            or: 'أو',
            yes: 'نعم',
            no: 'لا',
            save: 'حفظ',
            close: 'إغلاق',
            assessments: 'الإستبيانات',
            viewReport: "عرض التقرير",
            resume: "استكمال",
            start: "ابدأ",
            mins: "دقيقة",
            time: "الوقت",
            total: 'الكلي',
            remaining: 'المتبقي',
            langChange: 'جاري تحويل اللغة الآن',
            timer: 'التوقيت',
            passTime: 'مدة الإستبيان',
            actual: 'الوقت المستهلك',
            sureToLeave: 'جميع مدخلاتك لم يتم حفظها .. هل تريد مغادرة الإستبيان دون حفظ مدخلاتك؟',
            pageNotFound: 'الصفحة التي تحاول الوصول إليها غير موجودة أو مغلقة حالياً',
            errorMsg: 'حدث خطأ ما!',
            view: 'عرض',
            completed: 'استبيانات اكتملت',
            notStarted: 'استبيانات لم تبدأ بعد',
            inProgress: 'استبيانات قيد الإستكمال',
            notifications: 'الإشعارات',
            delete: 'حذف',

        },
        errorsList: {
            login: {
                errors: {
                    reqUser: 'يجب ادخال البريد الإلكتروني',
                    reqPass: 'يجب ادخال كلمة المرور',
                    minCharsOfPass: 'تتكون من 6 مدخلات على الأقل',
                    passMoreThanNChars: 'كلمة المرور يجب ان تكون أكثر من {n} مدخلات',
                }
            },
            signup: {
                errors: {
                    reqUserName: 'يجب ادخال اسم المستخدم',
                    reqBio: 'يجب ادخال السيرة الذاتية',
                    reqMobile: 'يجب ادخال رقم الجوال',
                    reqEmail: 'يجب ادخال بريد إلكتروني',
                    mobileMustValid: 'يجب ادخال رقم جوال صالح',
                    emailMustValid: 'يجب ادخال بريد الكتروني صالح',
                    reqPass: 'يجب ادخال كلمة المرور',
                    minCharsOfPass: 'تتكون من 6 مدخلات على الأقل',
                    passMoreThanNChars: 'كلمة المرور يجب ان تكون أكثر من {n} مدخلات',
                    bioMoreThanNChars: 'السيرة الذاتية يجب ان تكون أكثر من {n} مدخلات',
                }
            },

        },
        forgotPassword: {
            successMsg: 'تم إرسال رابط تغيير كلمة المرور إلى بريدك الإلكتروني.',
            goToMail: 'الذهاب إلى بريدك الإلكتروني',
            willSentToEmail: 'سيتم إرسال رابط تغيير كلمة المرور إلى بريدك الإلكتروني',
            welcomeMsg: 'نسيت كلمة المرور؟',
            resetTitle: 'أعد تعيين كلمة المرور الآن',
            resetBtn: 'تغيير كلمة المرور',
        },
        resetPassword: {
            passDontMatch: 'كلمتي السر غير متطابقتان!',
            confirmedNewPassPlaceholder: 'اعد ادخال كلمة المرور',
            newPassPlaceholder: ' كلمة المرور الجديدة',
            welcomeMsg: 'تغيير كلمة المرور الآن',
            resetTitle: 'أدخل كلمة المرور الجديدة',
        },
        ProfileEdit: {
            submitBtn: 'تعديل البيانات الشخصية',
            title: 'تعديل بياناتك الشخصية',
            firstName: 'الإسم',
            lastName: 'الإسم الأخير',
            position: 'الموقع الإداري',
            address: 'العنوان',
            newPassword: 'كلمة المرور الجديدة',
            resetPassword: 'إذا أردت تغيير كلمة المرور',
            oldPassword: 'كلمة المرور الحالية',
            oldPassword2: 'يجب إدخال كلمة المرور الحالية',

        },
        navMenu: {
            authorized: {
                // home: { title:'الرئيسية', icon:'mdi-home-outline', href: '/'},
                profile: { title: 'الصفحة الشخصية', icon: 'mdi-home-outline', href: '/profile' },
                // about: { title: 'عنّا', icon: 'mdi-information-outline', href: '/about'},

            },
            unAuthorized: {
                // home: { title:'الرئيسية', icon:'mdi-home-outline', href: '/'},
                signup: { title: 'التسجيل', icon: 'mdi-account-plus-outline', href: '/signup' },
                login: { title: 'الدخول', icon: 'mdi-login-variant', href: '/login' },

            }


        },
        login: {

            welcomeMsg: 'أهلاً بك',
            signinTitle: 'سجل الدخول الآن',
            emailPlaceholder: 'البريد الإلكتروني',
            passPlaceholder: 'كلمة المرور',
            signinBtn: 'الدخول',
            signupBtn: 'مستخدم جديد',
            errors: {
                reqUser: 'يجب ادخال البريد الإلكتروني',
                reqPass: 'يجب ادخال كلمة المرور',
                minCharsOfPass: 'تتكون من 6 مدخلات على الأقل',
                passMoreThanNChars: 'كلمة المرور يجب ان تكون أكثر من {n} مدخلات',

            }
        },
        signup: {
            successDialogH: 'شكراً لك .. تم التسجيل بنجاح',
            successDialogP: 'جاري مراجعة بياناتك من قبل مسؤولي المؤسسة وسيتم اخطارك عبر البريد الإلكتروني فور تفعيل حسابك',
            successDialoglink: 'تسجيل الدخول',
            verifyMsg: 'ستصلك رسالة تفعيل حسابك على بريدك الإلكتروني.',
            loginBtn: 'لديك حساب بالفعل',
            signupBtn: 'تسجيل',
            welcomeMsg: 'أهلاً بك',
            signupTitle: 'تسجيل مستخدم جديد',
            namePlaceholder: 'اسم المستخدم',
            workSectorPlaceholder: 'قطاع العمل',
            workSectorNoText: 'لا يوجد قطاع',
            sectorPlaceholder: 'القطاع',
            departmentPlaceholder: 'الإدارة',
            sectionPlaceholder: 'القسم',
            mobilePlaceholder: 'رقم الجوال',
            passPlaceholder: 'كلمة المرور',
            bioPlaceholder: 'نُبذه عنك؟',
            emailPlaceholder: 'البريد الإلكتروني',
            errors: {
                reqUserName: 'يجب ادخال اسم المستخدم',
                reqBio: 'يجب ادخال السيرة الذاتية',
                reqMobile: 'يجب ادخال رقم الجوال',
                reqEmail: 'يجب ادخال بريد إلكتروني',
                mobileMustValid: 'يجب ادخال رقم جوال صالح',
                emailMustValid: 'يجب ادخال بريد الكتروني صالح',
                reqPass: 'يجب ادخال كلمة المرور',
                minCharsOfPass: 'تتكون من 6 مدخلات على الأقل',
                passMoreThanNChars: 'كلمة المرور يجب ان تكون أكثر من {n} مدخلات',
                bioMoreThanNChars: 'السيرة الذاتية يجب ان تكون أكثر من {n} مدخلات',

            }
        },

        logout: {
            logoutConfirm: 'متأكد أنك تريد تسجيل الخروج؟'
        },
        Profile: {
            editProfileImg: 'تغيير الصورة الشخصية',
            MainDetails: "البيانات الأساسية",
            editprofile: "تعديل البيانات",
            YourBio: "السيرة الذاتية",
            YourPhone: "رقم التليفون",
        },
        Asses: {
            mustFinished: 'يجب الإنتهاء منها',
            page: 'صفحة',
            choosePage: 'اختر الصفحة',
            date:'التاريخ',
            withoutPoints:'بدون نقاط',
            noResults: 'لا توجد نتائج',
            degree: 'الدرجة',
            answerTime: 'وقت الإجابة',
            timeNotSet: 'غير محدود بوقت',
            expDateNotSet: 'لم يحدد تاريخ الإنتهاء',
            closedForNow: 'هذا الإستبيان مغلق الآن',
            assesCreationDate: 'تاريخ إنشاء الإستبيان',
            assesFillDate: 'تاريخ ملىء الإستبيان',
            assesNumId: 'رقم الإستبيان',
            assesCorrectiveANum: 'الإجراءات التصحيحية',
            assesTimeToFinish: 'الوقت المحدد',
            noAssessments: 'ليس لديك أي استبيانات مسجلة',
            validTypes: "الملفات المرفقة لابد ان تكون بالامتدادات التالية (.pdf | .png | .jpeg | .jpg | .doc | .xls | .xlsx | .docx)",
            expiryDate: 'ينتهي في',
            qNum: 'عدد الأسئلة',
            attatchments: 'الملفات المرفقة',
            type: 'نوع السؤال',
            report: "التقرير",
            download: "استخراج تقرير بإجاباتك",
            progress: "نسبة التقدم",
            pointsCount: "مجموع النقاط",
            mypoints: "النقاط المحصلة",
            time: "وقت الإستبيان",
            search: "البحث",
            questions: 'الأسئلة',
            nosearch: 'لا توجد نتائج للبحث',
            pause: 'الحفظ والإستكمال في وقت لاحق',
            num: 'الرقم',
            question: 'السؤال',
            answer: 'الإجابة',
            correctAction: 'الإجراءات التصحيحية',
            remainTime: 'الوقت المتبقي',
        },
        org: {
            orgName: 'اسم المؤسسة',
            orgAbout: 'عن المؤسسة',
            orgAddress: 'عنوان المؤسسة',
        },
    },
    'en': {
        public: {
            colleaguesTotalPoints: 'Colleagues Total Points',
            company: 'Company',
            department: 'Department',
            departmentPoints: 'Department Points',
            rank: 'Rank',
            comparedToColleagues: 'Compared to Colleagues',
            participations: 'participations',
            from: 'from',
            noNotifications: 'There is no notifications',
            selfassessment: 'Self-Assessment Gate',
            points: 'Points',
            profile: 'Profile',
            redeemPoints: 'Redeem Points Now',
            pointsBoard: 'Leader Board Points Earned',
            socilaMedia: 'Social Media',
            relatedLinks: 'Related Links',
            signout: 'Sign out',
            lang: 'Language',
            ar: 'Arabic',
            en: 'English',
            brandName: 'Lorem Ipsum is simply',
            brandCategoryHeader: 'Fast feedback',
            brandCategoryDesc: `Time is important, we don't want you to waste it. Here you can get a massive feedback from real users in minutes. And if your stuff is appreciated you won't only get positive feedback but also lovely and sincere fans <3`,
            brandSite: 'Go To Website',
            brandAbout: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            brandRights: 'All Rights Reserved To ',
            or: 'Or',
            yes: 'Yes',
            no: 'No',
            save: 'Save',
            close: 'Close',
            assessments: 'Assessments',
            viewReport: "View Report",
            resume: "Resume",
            start: "Start",
            mins: "mins",
            time: "Time",
            total: 'Total',
            remaining: 'Remaining',
            langChange: 'Proccessing New Language',
            timer: 'The Timer',
            passTime: 'Time To Pass',
            actual: 'Consumed Time',
            sureToLeave: 'YOUR INPUTS WILL NOT BE SAVED .. Are You Sure You Want To Leave Without Saving?',
            pageNotFound: 'Page Not Found Or Closed For Now',
            errorMsg: 'Something Went Wrong!',
            view: 'View',
            completed: 'Completed Assessments',
            notStarted: 'Not Started Assessments',
            inProgress: 'In Progress Assessments',
            notifications: 'Notifications',
            delete: 'Delete',

        },
        errorsList: {
            login: {
                errors: {
                    reqUser: 'E-mail is required',
                    reqPass: 'password is required',
                    passMoreThanNChars: 'password must be more than {n} characters',
                    minCharsOfPass: 'at least 6 characters',

                }
            },
            signup: {
                errors: {
                    reqUserName: 'user name is required',
                    reqBio: 'bio is required',
                    reqMobile: 'mobile number is required',
                    reqEmail: 'E-mail is required',
                    mobileMustValid: 'mobile number should be valid',
                    emailMustValid: 'E-mail must be valid',
                    reqPass: 'password is required',
                    passMoreThanNChars: 'password must be more than {n} characters',
                    minCharsOfPass: 'at least 6 characters',
                    bioMoreThanNChars: 'Bio must be more than {n} characters',


                }
            },

        },
        forgotPassword: {
            successMsg: 'Reset Password Link Has Been Sent To Your Email',
            goToMail: 'Go To Your Gmail',
            willSentToEmail: 'Reset Password Link Will Be Sent To Your Email',
            welcomeMsg: 'Forgot Your Password?',
            resetTitle: 'Reset Your Password',
            resetBtn: 'Reset',
        },
        resetPassword: {
            passDontMatch: 'Password Does Not Match!',
            confirmedNewPassPlaceholder: 'Re Enter Your Password',
            newPassPlaceholder: 'Enter New Password',
            welcomeMsg: 'Reset Your Password Now',
            resetTitle: 'enter Your New Password',
        },
        ProfileEdit: {
            submitBtn: 'Update Profile',
            title: 'Edit Profile Info',
            firstName: 'Name',
            lastName: 'Last Name',
            position: 'Position',
            address: 'Address',
            newPassword: 'New Password',
            resetPassword: 'If You Want To Change Your Password',
            oldPassword: 'Current Password',
            oldPassword2: 'Please enter your current password',
        },
        navMenu: {
            authorized: {
                // home: { title:'Home', icon:'mdi-home-outline', href: '/'},
                profile: { title: 'Profile', icon: 'mdi-home-outline', href: '/profile' },
                // about: { title: 'About', icon: 'mdi-information-outline', href: '/about'},

            },
            unAuthorized: {
                // home: { title:'Home', icon:'mdi-home-outline', href: '/'},
                signup: { title: 'Signup', icon: 'mdi-account-plus-outline', href: '/signup' },
                login: { title: 'Login', icon: 'mdi-login-variant', href: '/login' },

            }



        },
        login: {

            welcomeMsg: 'WELCOME',
            signinTitle: 'Sign In Now',
            emailPlaceholder: 'E-mail',
            passPlaceholder: 'Password',
            signinBtn: 'SIGN IN',
            signupBtn: 'SIGN UP',

            errors: {
                reqUser: 'Email is required',
                reqPass: 'Password is required',
                minCharsOfPass: '6 characters at least',
                passMoreThanNChars: 'Password must be more than {n} characters',

            }

        },
        signup: {
            successDialogH: 'Thank You, You have registered successfully',
            successDialogP: 'Organization admin is processing your data, you will be informed once your account verified',
            successDialoglink: 'Login Now',
            verifyMsg: 'Once your account is verified, we will Email you.',
            loginBtn: 'Already Have Account',
            signupBtn: 'Sign up',
            welcomeMsg: 'WELCOME',
            signupTitle: 'Sign Up Now',
            namePlaceholder: 'User Name',
            workSectorPlaceholder: 'Work Sector',
            workSectorNoText: 'No sub-sector',
            sectorPlaceholder: 'Sector',
            departmentPlaceholder: 'Department',
            sectionPlaceholder: 'Section',
            mobilePlaceholder: 'Mobile Number',
            passPlaceholder: 'Password',
            bioPlaceholder: 'Your Bio',
            emailPlaceholder: 'E-mail',

        },

        logout: {
            logoutConfirm: 'Are you sure you want to log out?'
        },

        Profile: {
            editProfileImg: 'Edit Profile Image',
            MainDetails: "Main Details",
            editprofile: "Edit my profile",
            YourBio: "A short Bio about You",
            YourPhone: "Your Phone number",
        },
        Asses: {
            mustFinished: 'Must be Finished',
            page: 'page',
            choosePage: 'Choose Page',
            date:'Date',
            withoutPoints:'without points',
            noResults: 'No Results',
            degree: 'Degree',
            answerTime: 'Answer Time',
            timeNotSet: 'Time Not Set',
            expDateNotSet: 'Expiry Date Not set',
            closedForNow: 'Closed For Now',
            assesCreationDate: 'Created At',
            assesFillDate: 'Filled At',
            assesNumId: 'Assessment ID',
            assesCorrectiveANum: 'Corrective Actions Number',
            assesTimeToFinish: 'Time To Finish',
            noAssessments: 'There is No Assessments Recorded for Now',
            validTypes: "Attatched Files Must be in these Formats (.pdf | .png | .jpeg | .jpg | .doc | .xls | .xlsx | .docx)",
            expiryDate: 'Ends At',
            qNum: 'Questions Numper',
            attatchments: 'Attatched Files',
            type: 'Type',
            report: "Report",
            download: "Export Report",
            progress: "My Progress",
            pointsCount: "Total Points",
            mypoints: "My Points",
            time: "Actual Time",
            search: 'Search',
            questions: 'Questions',
            nosearch: 'No search results to view',
            pause: 'Save and complete in another time',
            num: 'Number',
            question: 'Question',
            answer: 'Answer',
            correctAction: 'Corrective Actions',
            remainTime: 'Remaining Time',

        },
        org: {
            orgName: 'Organization Name',
            orgAbout: 'About Organization',
            orgAddress: 'Organization Address',
        },
    },

};

const i18n = new VueI18n({
    locale: 'ar', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
});

export default i18n;