<template>
    <v-form @submit.prevent="handleSubmit" ref="form" id="formedit">
        <v-card-text>
        <v-container>
            <v-row>
            
                <v-col cols="12">
                    <v-text-field 
                    label="Your Phone Number" 
                    type="phone"
                    v-model="userDetails.mobile" 
                    :loading="loadingForm" 
                    :placeholder="$t('Profile.YourPhone')"
                    :color="colors.brandPTextColor"
                    :background-color="colors.brandSecColor"
                    light
                    height="40"
                    full-width
                    append-icon="mdi-phone" 
                    :rules="mobileRules"
                    >
                    </v-text-field>
                </v-col>
                
                <v-col cols="12">
                    <v-textarea 
                    label="A short Bio about You" 
                    type="text"  
                    v-model="userDetails.bio" 
                    :loading="loadingForm" 
                    no-resize
                    :placeholder="$t('Profile.YourBio')"
                    :color="colors.brandPTextColor"
                    :background-color="colors.brandSecColor"
                    light
                    height="40"
                    full-width
                    append-icon="mdi-account-card-details"
                    :rules="bioRules"

                    >
                    </v-textarea>
                </v-col>
            </v-row>
        </v-container>
        </v-card-text>
        <v-card-actions>
        <div class="flex-grow-1"></div>
            <v-btn :color="colors.brandPrimColor" :disabled="loadingForm" text @click.stop="$emit('click')">
                {{$t('public.close')}}
            </v-btn>
            <v-btn :color="colors.brandPrimColor" :loading="loadingForm" text type="submit" >
                {{$t('public.save')}}
            </v-btn>
        </v-card-actions>
    </v-form>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            userDetails: {
                bio: this.data.profile.bio,
                mobile: this.data.profile.mobile,
            },
            bioRules: [
                v => !!v || 'Bio is required',
                v => (v && v.length >= 10) || 'Bio must be more than 10 characters',
            ],
            mobileRules: [
                v => !!v || 'Mobile number is required',
                v => /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/.test(v) || 'Mobile number should be valid',
            ],
        }
    },
    methods: {
        handleSubmit() {
            if (this.$refs.form.validate()) {
                this.$store.dispatch('EDIT_USER_DETAILS', this.userDetails)
                .then(() => {
                    this.$emit('click')
                })
            }
            
        }
    },
    
    computed: {
        ...mapGetters(['colors', 'loadingForm','theme', 'userCredentials'])
    },
    beforeDestroy() {
        this.$store.dispatch('CLEAR_ERROR')
    },
    mounted() {
        
    },
}
</script>

