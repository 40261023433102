<template>

    <v-app-bar
        height="100"
        color="#fff"
        shrink-on-scroll
        scroll-target="#scrolling-techniques-2"
        class="mb-5"
         >
            <template v-slot:img="{ props }">
                <v-img
                v-bind="props"
                gradient="to top right, rgba(44, 62, 80, 0.81), rgba(44, 62, 80, 0.35)"
                radious
                ></v-img>
            </template>

            <router-link to="/"><img :src="theme.organization.logo"  class="navLogo"></router-link> 

            <v-toolbar-title>{{$t('public.selfassessment')}}</v-toolbar-title>

            <v-spacer></v-spacer>
            
            <div v-if="isAuthenticated">
                <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-x
                    >
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" class="brandPrimColor">
                            <v-icon>fas fa-bell</v-icon>
                        </v-btn>
                       
                    </template>

                    <v-card>
                        <v-list>
                        <v-list-item>
                            <v-list-item-avatar>
                            <img src="/img/notify.png" alt="notify">
                            </v-list-item-avatar>

                            <v-list-item-content>
                            <v-list-item-title>{{$t('public.notifications')}}</v-list-item-title>
                           <v-list-item-subtitle v-if="!notificationsData.data">{{$t('public.noNotifications')}}</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                            <v-btn
                                :class="fav ? 'red--text' : ''"
                                icon
                                @click="fav = !fav"
                            >
                                <v-icon>mdi-heart</v-icon>
                            </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                        </v-list>

                        <v-list >
                            <v-list-item class="notificationsList"  v-for="(item, x ) in notificationsData.data" :key="item.id">
                                <v-alert 
                                    dismissible
                                    color="cyan"
                                    border="left"
                                    elevation="2"
                                    colored-border
                                    v-if="item.type=='NewSurvey'"
                                    >
                                    <h4 v-html="item.title"></h4>
                                    {{item.message}}
                                </v-alert>
                                
                                <v-alert
					
                                    dismissible
                                    color="red"
                                    border="left"
                                    elevation="2"
                                    colored-border
                                    v-if="item.type=='CorrectiveAction'"
                                    >
                                     <h4 v-html="item.title"></h4>
                                    {{item.message}}
                                </v-alert>

                                <v-alert
                                
                                    dismissible
                                    color="orange"
                                    border="left"
                                    elevation="2"
                                    colored-border
                                    v-if="item.type=='Closed'"
                                    >
                                        <h4 v-html="item.title"></h4>
                                    {{item.message}}
                                </v-alert> 
                            </v-list-item>
                        </v-list>

                   
                    </v-card>
                </v-menu>
            </div>

            <v-btn icon v-if="!['assessmentEdit'].includes($route.name)">
                <div 
                    v-for="entry in languages"
                    :key="entry.title"
                    @click="changeLocale(entry.language)"
                    class="lang-convert"
                >
                    <flag 
                    :iso="entry.flag" 
                    v-bind:squared=false 
                    v-if='entry.language !== locale' 
                    />
                </div>
            </v-btn>

            <div  v-if="isAuthenticated" class="welcomeUser brandPrimColor">
                {{$t('login.welcomeMsg')}}, <div>{{userCredentials.profile.firstname}}</div>
            </div>
        
        </v-app-bar>
    
    
</template>

<style lang="scss" >
    header.v-toolbar {
        flex: 0 !important;

        .v-toolbar__content .router-link-active {
            width: 200px;
        }

    }

    .navLogo {
        max-width: 100%;
        max-height: 80px;
    }
    .welcomeUser {
        margin: 0 20px;
        font-size: 15px;

        div {
            color: rgb(134, 134, 134)
        }
    }

    .v-toolbar--prominent .v-toolbar__content{
        align-items: center !important;
    }

    .notifyBadge {
        height: 5px;
        width: 5px;
        background: #78c37b;
        border-radius: 100px;

    }

    .notifyBtn {
        background: transparent !important;
        box-shadow: none;
        width: auto;
        min-width: unset !important;
            align-items: baseline;


        &:hover {
            background-color: #fff !important;
        }
    }

    .notificationsList {
        flex-direction: column;
        align-items: baseline;

        div {
            width: 100%;
        }    
    }


    .lang-convert {
        cursor: pointer;
    }

    a.main-nav-link{
        color: var(--brandHTextColor) !important;
        font-size: 1rem;
        padding: 5px 10px;


        &:hover{
            color: var(--brandPrimColor) !important;
        }

        &.active{
            background: var(--brandSecColor) !important;
            border-bottom: 2px solid var(--brandPrimColor) !important;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }

    @media  only screen and (max-width: 993px) {
        .hidden-tab-and-down {
            display: none !important;
        }
    }

    @media  only screen and (min-width: 994px) {
        .hidden-tab-and-top {
            display: none !important;
        }
    }
</style>


<script>

// import AppLogoutModal from '@/components/AppLogoutModal.vue';



//VUEX
import { mapGetters } from 'vuex';
import i18n from '@/plugins/i18n';
import Api from '../../service/Api';


import { mdiLogin, mdiLogout } from '@mdi/js';




export default {
    components: {
        // AppLogoutModal,
    }, 
    
    data: () => ({
        languages: [            
            { flag: 'us', language: 'en', title: i18n.messages[i18n.locale].public.en },            
            { flag: 'sa', language: 'ar', title: i18n.messages[i18n.locale].public.ar }       
        ],
        

        svg: {
            login: mdiLogin,
            logout: mdiLogout,
        }, 


        fav: true,
      menu: false,
      message: false,
      hints: true,
       
    }),
    computed: {
        ...mapGetters(['userCredentials', 'isAuthenticated', 'userNotifications', 'locale', 'theme', "getAuthNavMenuItems", "getUnAuthNavMenuItems", "errors","notificationsData"])
    },
    methods: {
        changeLocale(newLocale) {
            this.$store.commit('SET_LOADING', { name: 'lang', value: true});
            let LocalOrgSlug = process.env.VUE_APP_ORG_SLUG;
            let full = window.location.host
            let parts = full.split('.')
            let subDomain = parts[0]
            let orgSlug = LocalOrgSlug ? LocalOrgSlug : subDomain;
            localStorage.getItem('FBidToken') ? this.$store.dispatch('EDIT_USER_DETAILS', {locale : newLocale}) : false
            this.$store.dispatch('GET_ORG_THEME',{orgSlug: orgSlug, locale: newLocale})
            this.$store.commit('SET_CLEAR_ERROR')
            let x = document.getElementsByClassName('v-messages__message');
            for (let index = 0; index < x.length; index++) {
                const element = x[index];
                element.innerHTML = ""
            }
            setTimeout(() => {
                this.$store.commit('SET_LOADING', { name: 'lang', value: false});
            }, 3000);
           
        },
        
    },
    mounted() {
        // this.$store.dispatch('FETCH_NOTIFICATIONS_DATA');
        
    }
}
</script>

