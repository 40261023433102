<template>
    <v-container >
        <v-row justify="center"  class="login">
            <!-- <v-col sm="12" md="6"> -->
                <h2>{{$t('login.welcomeMsg')}},</h2>
                <h4>{{$t('login.signinTitle')}}!</h4>
                <AppLoginForm></AppLoginForm>
            <!-- </v-col> -->
            <!-- <v-col v-if='windowWidth > 960'  sm="0" md="6" >
                <div class="login-bg"></div>
            </v-col> -->

        </v-row>
        
    </v-container>
</template>

<script>
// import i18n from '@/plugins/i18n';


// COMPONENTS
import AppLoginForm from '@/components/Form/AppLoginForm';


export default {
    components: {
        AppLoginForm
    },
     data() {
        return {
            windowWidth: window.innerWidth
        }
    },
    mounted() {
        window.onresize = () => {
            this.windowWidth = window.innerWidth
        }
    }
}
</script>

<style lang="scss">
.login {
    flex-direction: column;
    align-items: center;
}
</style>

