export default {

    isAuthenticated: state => !!state.token,
    theme: state => state.orgTheme,
    colors: state => state.colors,
    locale: state => state.locale,
    getAuthNavMenuItems: state => state.authNavMenuItems,
    getUnAuthNavMenuItems: state => state.unAuthNavMenuItems,
    getErrorsList: state => state.errorsList,
    errors: state => state.error,
    userCredentials: state => state.authUser,
    loadingUser: state => state.loading.user,
    loadingUI: state => state.loading.ui,
    loadingLang: state => state.loading.lang,
    loadingForm: state => state.loading.form,
    loadingSurvey: state => state.loading.survey,
    loadingQLoader: state => state.loading.qLoader,
    completedAssessmentsList: state => state.completedAssessmentsList,
    notcompletedAssessmentsList: state => state.notcompletedAssessmentsList,
    notstartedAssessmentsList: state => state.notstartedAssessmentsList,
    sectorsList: state => state.sectorsList,
    assessmentData: state => state.assessmentData,
    userData: state => {
        if (state.authUser) {
            return state.authUser
        }
    },
    notificationsData: state => state.notificationsData,
    // screamList: state => state.screams,
    // userLikes: state => state.authUser.likes,
    // userNotifications: state => state.authUser.notifications,
    // selectedScream: state => state.selectedScream.comments,
    // unreadNotifications: state => {
    //     if(state.authUser.notifications) {
    //         const unread = state.authUser.notifications.filter(function(notification) {
    //             return notification.read === false
    //         })
    //     return !!unread.length
    //     }
    // },

}