import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './vuex'
import vuetify from './plugins/vuetify';
import Api from './service/Api';

import axios from 'axios';
import i18n from '@/plugins/i18n';
import FlagIcon from 'vue-flag-icon';
Vue.use(FlagIcon);

import day from './filters/day';

Vue.use(day);

import jwtDecode from 'jwt-decode';

// import VuejsDialog from 'vuejs-dialog';
// import VuejsDialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js'; // only needed in custom components
 
// // include the default style
// import 'vuejs-dialog/dist/vuejs-dialog.min.css';
 
// // Tell Vue to install the plugin.
// Vue.use(VuejsDialog);


// import 'propdoc/style.scss'

// // const token = localStorage.FBidToken

// const token = "pv-wL4hXOBADlzf05SYHDc_A3vO_TSpYJVQBtgx8"

// if (token) {
//   console.log(token)
//     const decodedToken = jwtDecode(token);
//     console.log(decodedToken)
//     if (decodedToken.exp * 1000 > Date.now()) {
//         axios.defaults.headers.common['Authorization'] = token;
//         store.dispatch('AUTH_USER', token);
//     }
//     if (decodedToken.exp * 1000 < Date.now()) {
//         store.dispatch('LOGOUT_USER');
//         // store.state.path = '/login';
//     }
// }

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App),
  beforeCreate() {
  },
}).$mount('#app')





