<template>
    <v-form @submit.prevent="loginHandlerSubmit" ref="form">
        <v-text-field
            v-model="loginUser.identity"
            :loading="loadingForm"
            :color="colors.brandPrimColor"
            light
            height="40"
            append-icon="mdi-email-outline"
            :rules='userNameRules'
            :label="$t('login.emailPlaceholder')"
            outlined
            name="userNameEmail"
        ></v-text-field>
        <v-text-field
            v-model="loginUser.password"
            :append-icon="showPassword ? svg.visibility : svg.visibilityOff"
            :type="showPassword ? 'text' : 'password'"
            name="input-10-1"
            :label="$t('login.passPlaceholder')"
            :loading="loadingForm"
            @click:append="showPassword = !showPassword"
            :color="colors.brandPrimColor"
            height="40"
            outlined
            autocomplete
            :rules='passwordRules'
  
        ></v-text-field>
        

        <div>
            <span>{{$t('forgotPassword.welcomeMsg')}} <router-link class="thirdBtn" to="/forgot-password">{{$t('forgotPassword.resetTitle')}}</router-link></span>

        </div>
        

        <!-------------------------  FORM ERRORS  ------------------->
        <div v-if="errors" class="subtitle1 text-capitalize red--text mt-5">
            <p v-for="(errorMessage, i) in errors" :key="i">{{errorMessage}}</p>
        </div>
        <!-------------------------  END FORM ERRORS ------------------->

        <div class="mt-5">
            <Button type="submit" large :loading="loadingForm" :class="{ 'mr-4': locale == 'en' }" class="primBtn"  :color="colors.brandPrimColor" elevation="0" dark>
                {{$t('login.signinBtn')}}
            </Button>
            <router-link v-if='theme.organization.allow_registration' class="v-btn mr-4 secBtn" to="/signup">{{$t('login.signupBtn')}}</router-link>            
        </div>
    </v-form>
</template>

<script>
// MIXINS
import { reset } from '@/mixins/mixins';
import i18n from '@/plugins/i18n';


// SVG ICONS
import { mdiEyeOutline, mdiEyeOffOutline  } from '@mdi/js';

//VUEX
import { mapGetters } from 'vuex';
import axios from 'axios';


export default {
    mixins: [reset],
    data() {
        return {
            showPassword: false,
            loginUser: {
                identity: '',
                password: ''
            },
            userNameRules: [
                v => !!v || i18n.t('errorsList.login.errors.reqUser'),
            ],
            passwordRules: [
                v => !!v || i18n.t('errorsList.login.errors.reqPass'),
                v => (v && v.length >= 6) || i18n.t('errorsList.login.errors.passMoreThanNChars', {'n': '6'}),

            ],
            svg: {
                visibility: mdiEyeOutline,
                visibilityOff: mdiEyeOffOutline,
            },
        }
    },
    methods: {
        loginHandlerSubmit() {
            if (this.$refs.form.validate()) {
                 this.$store.dispatch('SIGN_IN', {
                    identity: this.loginUser.identity,
                    password: this.loginUser.password,
                    locale: this.locale
                })
                .then(() => {
                    this.$router.push({name: 'profile'});
                })
            }
        },
        
    },
    computed: {
        ...mapGetters([ 'colors', 'errors', 'loadingForm', 'theme', 'locale'])
    },
    beforeDestroy() {
        this.$store.dispatch('CLEAR_ERROR')
    },
    mounted() {
        

    },
}
</script>
<style lang="scss" scoped>

    fieldset {
        display: none;
    }
</style>
