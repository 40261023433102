import axios from 'axios';
import Api from '../service/Api';
import router from '@/router'
import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/i18n';

import { log } from 'util';


export default {



    // LOGIN / REGISTRATION / LOGOUT / CLEAR ERRORS
    SIGN_IN: ({ dispatch, commit }, { identity, password, locale }) => new Promise((resolve, reject) => {
        commit('SET_LOADING', { name: 'form', value: true });


        if (process.env.VUE_APP_ORG_SLUG) {
            var sub = process.env.VUE_APP_ORG_SLUG
        } else {
            var full = window.location.host
            var parts = full.split('.')
            var sub = parts[0]
        }

        Api().post('login', { identity, password, locale, org: sub })
            .then((res) => {

                if (res.status === 200) {
                    const FBidToken = `Bearer ${res.data.data.token}`
                    localStorage.setItem('FBidToken', FBidToken)
                    dispatch('AUTH_SUCCESS', FBidToken);
                    // commit('SET_AUTH_USER', res.data)

                    dispatch('FETCH_AUTH_USER');
                    commit('SET_LOADING', { name: 'form', value: false });
                    resolve();
                }
            })
            .catch((error) => {
                commit('SET_ERROR', error.response.data.errors);
                commit('SET_LOADING', { name: 'form', value: false });
            })
    }),

    SIGN_UP: ({ dispatch, commit }, formNewUser) => new Promise((resolve) => {

        commit('SET_LOADING', { name: 'form', value: true });
        Api().post('/signup', formNewUser)
            .then((res) => {
                if (res.data.success) {

                    // const FBidToken = `Bearer ${res.data.data.token}`
                    // dispatch('AUTH_SUCCESS', FBidToken);
                    // dispatch('FETCH_AUTH_USER');

                    commit('SET_LOADING', { name: 'form', value: false });
                    resolve();

                } else if (res.data.status == 0) {
                    console.log(res.data.messages)
                    commit('SET_ERROR', res.data.messages);
                    commit('SET_LOADING', { name: 'form', value: false });
                }

            })
            .catch((error) => {
                commit('SET_ERROR', error.response.data.errors);

                commit('SET_LOADING', { name: 'form', value: false });
            })
    }),

    FORGOT_PASSWORD: ({ dispatch, commit }, { email, locale }) => new Promise((resolve, reject) => {
        commit('SET_LOADING', { name: 'form', value: true });
        Api().post('request-reset-password', { email, locale })
            .then((res) => {
                if (res.status === 200) {
                    console.log('success');
                    commit('SET_LOADING', { name: 'form', value: false });
                    resolve();
                }
            })
            .catch((error) => {
                commit('SET_ERROR', error.response.data.errors);
                commit('SET_LOADING', { name: 'form', value: false });
            })
    }),

    RESET_PASSWORD: ({ dispatch, commit }, { password, token, locale }) => new Promise((resolve, reject) => {
        commit('SET_LOADING', { name: 'form', value: true });
        Api().post('reset-password', { password, token, locale })
            .then((res) => {
                if (res.status === 200) {
                    const FBidToken = `Bearer ${token}`
                    localStorage.setItem('FBidToken', FBidToken)
                    dispatch('AUTH_SUCCESS', FBidToken);
                    // commit('SET_AUTH_USER', res.data)

                    dispatch('FETCH_AUTH_USER');
                    commit('SET_LOADING', { name: 'form', value: false });
                    resolve();
                }
            })
            .catch((error) => {
                commit('SET_ERROR', error.response.data.errors);
                commit('SET_LOADING', { name: 'form', value: false });
            })
    }),

    LOGOUT_USER: ({ commit }) => new Promise((response) => {
        localStorage.removeItem('FBidToken');
        delete axios.defaults.headers.common['Authorization'];
        commit('SET_USER_UNAUTHENTICATED', {})
        response()
    }),

    AUTH_SUCCESS: ({ commit }, FBidToken) => {
        localStorage.setItem('FBidToken', FBidToken)
        axios.defaults.headers.common['Authorization'] = FBidToken
        commit('SET_AUTHORIZATION', FBidToken);
    },

    AUTH_USER: ({ commit }, token) => { commit('SET_AUTHORIZATION', token) },

    CLEAR_ERROR: ({ commit }) => commit('SET_CLEAR_ERROR'),


    // FETCH/GET USER AUTHTENTICATED
    FETCH_ASSESSMENTS_LIST: ({ commit, dispatch }) => {
        // commit('SET_LOADING', { name: 'ui', value: true});
        const config = {
            headers: {
                "Authorization": localStorage.getItem('FBidToken'),
            }
        }
        Api().get('assessments', config)
            .then((res) => {
                commit('SET_NOTSTARTED_ASSESSMENTS_LIST', res.data)
                    // commit('SET_LOADING', { name: 'ui', value: false});

            })
            .catch((error) => {
                dispatch('LOGOUT_USER');
                router.push({ name: 'login' })

                commit('SET_ERROR', error.response.data.errors);
                // commit('SET_LOADING', { name: 'ui', value: false});

            })
        Api().get('assessments/not-complete', config)
            .then((res) => {
                commit('SET_NOTCOMPLETED_ASSESSMENTS_LIST', res.data)
                    // commit('SET_LOADING', { name: 'ui', value: false});

            })
            .catch((error) => {
                dispatch('LOGOUT_USER');
                router.push({ name: 'login' })

                commit('SET_ERROR', error.response.data.errors);
                // commit('SET_LOADING', { name: 'ui', value: false});

            })
        Api().get('assessments/completed', config)
            .then((res) => {
                commit('SET_COMPLETED_ASSESSMENTS_LIST', res.data)
                    // commit('SET_LOADING', { name: 'ui', value: false});

            })
            .catch((error) => {
                dispatch('LOGOUT_USER');
                router.push({ name: 'login' })

                commit('SET_ERROR', error.response.data.errors);
                // commit('SET_LOADING', { name: 'ui', value: false});

            })
    },

    FETCH_SECTORS_LIST: ({ commit, dispatch }) => {
        // commit('SET_LOADING', { name: 'ui', value: true});
        const config = {
            headers: {
                "Authorization": localStorage.getItem('FBidToken'),
            }
        }
        if (process.env.VUE_APP_ORG_SLUG) {
            var sub = process.env.VUE_APP_ORG_SLUG
        } else {
            var full = window.location.host
            var parts = full.split('.')
            var sub = parts[0]
        }
        Api().get(`sectors?organization=${sub}`)
            .then((res) => {
                commit('SET_SECTORS_LIST', res.data.data)
                    // commit('SET_LOADING', { name: 'ui', value: false});

            })
            .catch((error) => {
                dispatch('LOGOUT_USER');
                router.push({ name: 'login' })

                commit('SET_ERROR', error.response.data.errors);
                // commit('SET_LOADING', { name: 'ui', value: false});

            })

    },




    FETCH_ASSESSMENT_DATA: ({ commit, dispatch }, assessmentId) => {
        commit('SET_LOADING', { name: 'ui', value: true });
        const config = {
            headers: {
                "Authorization": localStorage.getItem('FBidToken'),
            }
        }
        Api().get(`/assessments/${assessmentId}`, config)
            .then((res) => {
                commit('SET_ASSESSMENT_DATA', res.data)
                commit('SET_LOADING', { name: 'ui', value: false });

            })
            .catch((error) => {
                dispatch('LOGOUT_USER');
                router.push({ name: 'login' })

                commit('SET_ERROR', error.response.data.errors);
                commit('SET_LOADING', { name: 'ui', value: false });

            })
    },


    FETCH_AUTH_USER: ({ commit, dispatch }) => {
        commit('SET_LOADING', { name: 'ui', value: true });
        const config = {
            headers: {
                "Authorization": localStorage.getItem('FBidToken'),
            }
        }
        Api().get('profile', config)
            .then((res) => {
                commit('SET_AUTH_USER', res.data)
                let LocalOrgSlug = process.env.VUE_APP_ORG_SLUG;
                let full = window.location.host
                let parts = full.split('.')
                let subDomain = parts[0]
                let orgSlug = LocalOrgSlug ? LocalOrgSlug : subDomain;
                dispatch('GET_ORG_THEME', { orgSlug: orgSlug, locale: res.data.profile.locale })

                commit('SET_LOADING', { name: 'ui', value: false });
            })
            .catch((error) => {
                dispatch('LOGOUT_USER');
                router.push({ name: 'login' })
                commit('SET_LOADING', { name: 'ui', value: false });
            })
    },

    // EDIT AUTH USER PROFILE
    UPLOAD_IMAGE: ({ commit, dispatch }, formData) => {
        const config = {
            headers: {
                "Authorization": localStorage.getItem('FBidToken'),
            }
        }
        Api().put('/profile', formData, config)
            .then((res) => {
                dispatch('FETCH_AUTH_USER')
            })
            .catch((error) => console.log(error))
    },

    EDIT_USER_DETAILS: ({ dispatch, commit }, userDetails) => new Promise((resolve, err) => {
        commit('SET_LOADING', { name: 'form', value: true });
        const config = {
            headers: {
                "Authorization": localStorage.getItem('FBidToken'),
            }
        }
        Api().put('/profile', userDetails, config)
            .then((res) => {
                dispatch('FETCH_AUTH_USER');
                commit('SET_LOADING', { name: 'form', value: false });
                resolve(res)
            })
            .catch((error) => {
                commit('SET_ERROR', error.response.data.errors);
                commit('SET_LOADING', { name: 'form', value: false });
            })
    }),


    // SELECT A USER NAME AND GET THE DATA TO SEE IN PROFILE USERS PAGE
    GET_USER: ({ commit }, handle) => {
        commit('SET_LOADING', { name: 'ui', value: true });
        Api().get(`user/${handle}`)
            .then((res) => {
                commit('SET_DATA_USER_PROFILE', res.data)
                commit('SET_LOADING', { name: 'ui', value: false });
            })
            .catch((error) => {
                commit('SET_LOADING', { name: 'ui', value: false });
            })
    },

    // UPDATE LOCALE
    UPDATE_LOCALE: ({ commit }, newLocale) => { commit('SET_LOCALE', newLocale) },

    // SET A PATH TO AUTHENTICATED/UNAUTHENTICATED USER BEFORE CREATE (LOGIN OR SIGNUP)
    TO_LAND: ({ commit }, pathName) => { commit('SET_LAND', pathName) },

    // UPDATE ORG THEME
    GET_ORG_THEME: ({ commit, dispatch }, { orgSlug, locale }) => {
        commit('SET_LOADING', { name: 'ui', value: true });
        const config = {
            headers: {
                "Authorization": localStorage.getItem('FBidToken'),
            }
        }
        Api().get(`/theme?org=${orgSlug}&lang=${locale}`, orgSlug, config)
            .then((res) => {
                dispatch('UPDATE_APP_LANG', { orgTranslations: res.data });
                // UPDATE TITLE & FAVICON
                let titleTag = document.createElement('title');
                titleTag.innerHTML = orgSlug;
                document.getElementsByTagName('head')[0].appendChild(titleTag);
                let linkTag = document.createElement('link');
                linkTag.type = 'image/png';
                linkTag.rel = 'shortcut icon';
                linkTag.href = res.data.organization.logo_icon;
                document.getElementsByTagName('head')[0].appendChild(linkTag);
                commit('SET_LOADING', { name: 'ui', value: false });

            })
            .catch((error) => {
                commit('SET_LOADING', { name: 'ui', value: false });
            })
    },



    // UPDATE APP LANGUAGE
    UPDATE_APP_LANG: ({ commit, dispatch }, { orgTranslations }) => {

        i18n.locale = orgTranslations.organization.locale;
        orgTranslations.organization.locale == 'ar' ? vuetify.framework.rtl = true : vuetify.framework.rtl = false;
        commit('SET_LOCALE', orgTranslations.organization.locale)
        commit('SET_ORG_THEME', orgTranslations)
        commit('SET_ERRORS_LIST', i18n.messages[i18n.locale].errorsList)
        commit('SET_AUTH_NAV_LIST', Object.values(i18n.messages[i18n.locale].navMenu.authorized))
        commit('SET_UN_AUTH_NAV_LIST', Object.values(i18n.messages[i18n.locale].navMenu.unAuthorized))

    },

    FETCH_NOTIFICATIONS_DATA: ({ commit, dispatch }) => {
        commit('SET_LOADING', { name: 'ui', value: true });
        const config = {
            headers: {
                "Authorization": localStorage.getItem('FBidToken'),
            }
        }
        Api().get('/notification', config)
            .then((res) => {
                commit('SET_NOTIFICATIONS_DATA', res.data)
                commit('SET_LOADING', { name: 'ui', value: false });

            })
            .catch((error) => {


            })
    },





}