import Vue from 'vue';
import Router from 'vue-router';
import store from './vuex';
import Home from './views/Home.vue';
import Profile from './views/UsersProfile.vue';
import Login from './views/Login.vue';
// import ForgotPassword from './views/ForgotPassword.vue';
// import ResetPassword from './views/ResetPassword.vue';
// import Signup from './views/Signup.vue';
// import ProfileEdit from './views/UsersProfileEdit.vue';
// import AssessmentEdit from './views/Assessment.vue';
// import About from './views/About.vue';

// const Login = resolve => {
//   require.ensure(['./views/Login.vue'], () => {
//       resolve(require('./views/Login.vue'));
//   }, 'login');
// };
const NotFound = resolve => {
  require.ensure(['./views/NotFound.vue'], () => {
      resolve(require('./views/NotFound.vue'));
  }, 'notFound');
};
const ForgotPassword = resolve => {
  require.ensure(['./views/ForgotPassword.vue'], () => {
      resolve(require('./views/ForgotPassword.vue'));
  }, 'forgotPassword');
};
const ResetPassword = resolve => {
  require.ensure(['./views/ResetPassword.vue'], () => {
      resolve(require('./views/ResetPassword.vue'));
  }, 'resetPassword');
};
const Signup = resolve => {
  require.ensure(['./views/Signup.vue'], () => {
      resolve(require('./views/Signup.vue'));
  }, 'signup');
};
// const Profile = resolve => {
//   require.ensure(['./views/UsersProfile.vue'], () => {
//       resolve(require('./views/UsersProfile.vue'));
//   }, 'profile');
// };
const ProfileEdit = resolve => {
  require.ensure(['./views/UsersProfileEdit.vue'], () => {
      resolve(require('./views/UsersProfileEdit.vue'));
  }, 'profileEdit');
};

const LeaderBoard = resolve => {
  require.ensure(['./views/UsersLeaderBoard.vue'], () => {
      resolve(require('./views/UsersLeaderBoard.vue'));
  }, 'leaderBoard');
};
const About = resolve => {
  require.ensure(['./views/About.vue'], () => {
      resolve(require('./views/About.vue'));
  }, 'about');
};
const AssessmentEdit = resolve => {
  require.ensure(['./views/Assessment.vue'], () => {
      resolve(require('./views/Assessment.vue'));
  }, 'assessmentEdit');
};
const AssessmentReport = resolve => {
  require.ensure(['./views/AssessmentReport.vue'], () => {
      resolve(require('./views/AssessmentReport.vue'));
  }, 'assessmentReport');
};



Vue.use(Router)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/profile')
}


const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/home',
      name: 'home',
      component: Home,
    },
    {
      path: '*',
      name: 'error',
      redirect: '/profile',

    },
    {
      path: '/not-found',
      name: 'notFound',
      component: NotFound,
    },
    {
      path: '/about',
      name: 'about',
      component: About,
    },
    
    {
      path: '/login',
      name: 'login',
      beforeEnter: ifNotAuthenticated,
      component: Login
    },
    {
      path: '/signup',
      name: 'signup',
      beforeEnter: ifNotAuthenticated,
      component: Signup
    },
    {
      path: '/forgot-password',
      name: 'forgotPassword',
      beforeEnter: ifNotAuthenticated,
      component: ForgotPassword,
    },
    {
      path: '/reset-password',
      name: 'resetPassword',
      beforeEnter: ifNotAuthenticated,
      component: ResetPassword,
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/profile/edit',
      name: 'profileEdit',
      component: ProfileEdit,
      meta: {
        requiresAuth: true
      },
      
    },
    {
      path: '/profile/leaderBoard',
      name: 'leaderBoard',
      component: LeaderBoard,
      meta: {
        requiresAuth: true
      },
      
    },
    {
      path: '/assessment/:id',
      name: 'assessmentEdit',
      component: AssessmentEdit,
      meta: {
        requiresAuth: true,
        saved: false,
      },
    },
    {
      path: '/assessment/report/:id',
      name: 'assessmentReport',
      component: AssessmentReport,
      meta: {
        requiresAuth: true
      },
    },

    


    
  ]
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(route => route.meta.requiresAuth)) {
    if(store.getters.isAuthenticated) {
      next();
      return
    }
    next({name: 'login'})
  } else {
    next();
  }
})

export default router;
