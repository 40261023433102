<template>
  <v-container fluid pa-3>
      <section>
        <v-row align="center" justify="center">
          <v-img
            :src="imageLink.sub_main"
            :lazy-src="imageLink.sub_main"
            aspect-ratio="1"
            class="header-img grey lighten-2"
            height="600px"
            position=" top left"
          ></v-img>
           <v-layout header-content column align-center justify-center class="white--text">
            <h1 class="brandPrimColor mb-2 text-center" style="font-weight: 900; ">{{$t('public.brandName')}}</h1>
            <div class="brandHTextColor subheading mb-3 text-center" style="font-weight: 900;">{{$t('public.brandAbout')}}</div>
            <v-btn class="brandPrimBgColor  lighten-2 mt-5" style="font-weight: 900" dark large to="/profile">
              {{$t('public.assessments')}}
            </v-btn>
          </v-layout>
        </v-row>
      </section>

      <section>
        <v-layout
          column
          wrap
          class="my-5"
          align-center
        >
          
          <v-flex xs12>
            <v-container grid-list-xl>
              <v-layout row wrap align-center>
                
                <v-flex xs12 md4>
                  <v-card class="elevation-0 transparent">
                    <v-card-text class="text-center">
                      <i class="fas fa-file-signature brandPrimColor text--lighten-2 fa-5x"></i>
                    </v-card-text>
                    <v-card-title primary-title class="layout justify-center">
                      <div class="headline">{{$t('public.brandCategoryHeader')}}</div>
                    </v-card-title>
                    <v-card-text class='text-center'>
                      {{$t('public.brandCategoryDesc')}} 
                    </v-card-text>
                  </v-card>
                </v-flex>

                <v-flex xs12 md4>
                  <v-card class="elevation-0 transparent">
                    <v-card-text class="text-center">
                      <i class="fas fa-file-alt brandPrimColor text--lighten-2 fa-5x"></i>
                    </v-card-text>
                    <v-card-title primary-title class="layout justify-center">
                      <div class="headline">{{$t('public.brandCategoryHeader')}}</div>
                    </v-card-title>
                    <v-card-text class='text-center'>
                      {{$t('public.brandCategoryDesc')}} 
                    </v-card-text>
                  </v-card>
                </v-flex>

                <v-flex xs12 md4>
                  <v-card class="elevation-0 transparent">
                    <v-card-text class="text-center">
                      <i class="fas fa-file brandPrimColor text--lighten-2 fa-5x"></i>
                    </v-card-text>
                    <v-card-title primary-title class="layout justify-center">
                      <div class="headline">{{$t('public.brandCategoryHeader')}}</div>
                    </v-card-title>
                    <v-card-text class='text-center'>
                      {{$t('public.brandCategoryDesc')}} 
                    </v-card-text>
                  </v-card>
                </v-flex>
                
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
      </section>

      <section>
        <v-parallax :src="imageLink.main" height="380">
          <v-layout column align-center justify-center>
            <div class="headline white--text mb-3 text-center">{{$t('public.brandName')}}</div>
            <p class="text-center">{{$t('public.brandAbout')}}</p>
            <v-btn class="brandPrimBgColor  lighten-2 mt-5" dark large href="/pre-made-themes">
              {{$t('public.brandSite')}}
            </v-btn>
          </v-layout>
        </v-parallax>
      </section>

      
       <section>
        <v-container class="mt-5">
          <v-layout>
            <v-flex height="100px" xs12 class="text-center d-flex center">
                <img  width="25%" :src="imageLink.logo[0]">
            </v-flex>
            <v-flex height="100px" xs12 class="text-center d-flex center">
                <img  width="25%" :src="imageLink.logo[1]">
            </v-flex>
            <v-flex height="100px" xs12 class="text-center d-flex center">
                <img  width="25%" :src="imageLink.logo[2]">
            </v-flex>
            <v-flex height="100px" xs12 class="text-center d-flex center">
                <img  width="25%" :src="imageLink.logo[3]">
            </v-flex>
            
          </v-layout>
        </v-container>
     </section>

  </v-container>
</template>

<script>


export default {
  data: function() {
    return {
      title: "Endorfine",
      imageLink: {
        main:
          './img/main3.jpg',
        sub_main:
          './img/main.jpg',
        logo: [
          "./img/logo-mini.png",
          "./img/saudi-arabia.png",
          "./img/plan.png",
          "./img/aseel.png",

        ]
      },
      
    };
  },
  methods: {
    
  },

  computed: {
   
  },

  mounted: function() {
  }
};
</script>


<style scoped>
  .finedTitle {
    font-weight: 900;
    text-shadow: 2px 2px #000000;
  }

  .social-icon {
    font-size: 21px;
    color: white;
  }

  .header-content{
    position: absolute;
  }

  .header-img .v-image__image.v-image__image--cover{
    background-position: top left !important;
  }
</style>