<template>
  <div>
    <!--------------------- LOGOUT BUTTON ----------------------->
    <!-- <v-btn @click.stop="dialog = true" text icon>
      <v-icon>{{svg.logout}}</v-icon>
    </v-btn> -->
    <v-list-item link @click.stop="dialog = true">
        <v-list-item-icon>
          <v-icon small>fas fa-sign-out-alt</v-icon>
        </v-list-item-icon>

        <v-list-item-content>

          <v-list-item-title>{{$t('public.signout')}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    <!-- <v-btn fab small elevation="0" color="#32BCC3" dark>
      <v-icon>{{svg.logout}}</v-icon>
    </v-btn> -->
    <!--------------------- END LOGOUT BUTTON ----------------------->

    <v-dialog v-model="dialog" max-width="290" >
      <v-card>
        <v-card-title class="headline"></v-card-title>

        <v-card-text>
            {{$t('logout.logoutConfirm')}}
        </v-card-text>

        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn :color="colors.brandPrimColor " text @click="dialog = false" >
            {{$t('public.no')}}
          </v-btn>
          <v-btn :color="colors.brandPrimColor" text @click="logout" >
            {{$t('public.yes')}}
          </v-btn>
        </v-card-actions>
        
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// VUEX
import { mapGetters } from 'vuex';

// SVG ICONS
import { mdiExitToApp } from '@mdi/js';

export default {
  props: {
    scream: {
      type: Object
    }
  },
  data () {
    return {
      dialog: false,
      svg: {
        logout: mdiExitToApp,
      }
    }
  },
  methods: {
      logout() {
          this.$store.dispatch('LOGOUT_USER')
          .then(() => {
            this.dialog = false
            this.$router.push('/login')
          })
      }
  },
  computed: {
    ...mapGetters(['loadingUser', 'colors', 'theme' ])
  }
}
</script>

