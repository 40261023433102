<template>
  
  <v-app id="app" :style='myDynamicStyleVars'>
    <div class="text-center ui-preloader" v-if="loadingUI">
      <v-progress-circular
        :size="50"
        :color="colors.brandPrimColor"
        indeterminate
      ></v-progress-circular>
    </div>
    <div class="text-center ui-preloader change-lang" v-if="loadingLang">
      <div class="lds-css ng-scope">
        <div style="width:100%;height:100%" class="lds-ellipsis"><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div></div></div>
      <!-- <img src="../public/img/ellipsisLoader.gif" width="100px" :color="colors.brandPrimColor" alt=""> -->
      <div>{{$t('public.langChange')}}</div>
    </div>


    <Navbar @open-menu="onSideMenuClick" ></Navbar>

    <v-navigation-drawer v-model="drawer" absolute temporary >
        <v-list-item>
            <v-list-item-avatar>
            <v-img :src="theme.organization.logo_icon" ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
            <v-list-item-title>{{theme.organization.name}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense v-if="isAuthenticated">

            <v-list-item
            v-for="item in getAuthNavMenuItems"
            :key="item.title"
            :to="item.href"
            link
            >
            <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-list dense v-if="!isAuthenticated">

            <v-list-item
            v-for="item in getUnAuthNavMenuItems"
            :key="item.title"
            :to="item.href"
            link
            >
            <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>

    <v-content>
      <transition mode='out-in' name="slide">
        <router-view></router-view>
      </transition>
    </v-content>

    <v-footer
      
      padless
    >
      <v-card
        flat
        tile
        class=" text-center brandPrimeColor"
        width='100%'
      >

        <!-- <v-card-text class='footer-menu pt-0 pb-0' v-if="theme.footer.links.length" >
            <a
            v-for="(link, index) in theme.footer.links"
            :key="index"
            :color="colors.brandHTextColor"
            >

                <v-btn
                :href="link.href"
                v-if="link.title"
                text
                rounded
                class="my-2"
              >
              {{ link.title }}
              </v-btn>
            </a>
        </v-card-text>

        <v-card-text v-if="theme.footer.social_media.length">
          <span v-for="icon in theme.footer.social_media"
              :key="icon.title">
              <v-btn
              :href="icon.href"
              class="mx-4 white--text"
              icon
              v-if="icon.href" 
            >
              <v-icon size="24px">fab fa-{{ icon.title }}</v-icon>
            </v-btn>
          </span>
        </v-card-text> -->

        <!-- <v-card-text class="white--text pt-0 " v-if="theme.organization.about">
          {{theme.organization.about}}
        </v-card-text> -->

        <v-card-text class="brandPrimeColor pt-0" v-if="theme.organization.name">
          {{ new Date().getFullYear() }} — {{$t('public.brandRights')}} <strong>{{theme.organization.name}}</strong> 
        </v-card-text>
      </v-card>
    </v-footer>


  </v-app>
</template>

<script>

//Vuex
import { mapGetters } from 'vuex';
import Api from './service/Api';


// COMPONENTS
import router from './router'

import i18n from '@/plugins/i18n';
import Navbar from './components/Layout/AppNavbar';
import { log } from 'util';





export default {
  name: 'App',
  components: {
    Navbar
  },
  data() {    
    return { 
      drawer: null,
      routeName:"",
        
    };
  },
  
  mounted() {
    let LocalOrgSlug = process.env.VUE_APP_ORG_SLUG;
    let full = window.location.host
    let parts = full.split('.')
    let subDomain = parts[0]
    let orgSlug = LocalOrgSlug ? LocalOrgSlug : subDomain;
    if (this.isAuthenticated) {
      this.$store.dispatch('FETCH_AUTH_USER')
    } else {
      this.$store.dispatch('GET_ORG_THEME',{orgSlug: orgSlug, locale: this.locale})
    }

  }, 

  methods: { 

    onSideMenuClick() {
      this.drawer = !this.drawer;
      
    },


    
    
  },
 
  computed: {
    ...mapGetters(["theme", 'colors', "locale", "loadingUI", "isAuthenticated", "getAuthNavMenuItems", "getUnAuthNavMenuItems", "loadingLang"]),

    myDynamicStyleVars(){
        return {
            '--brandPrimColor': this.colors.brandPrimColor,
            '--brandSecColor': this.colors.brandSecColor,
            '--brandHTextColor': this.colors.brandHTextColor,
            '--brandPTextColor': this.colors.brandPTextColor,
            '--brandBlackColor': this.colors.brandBlackColor,
            '--brandGrayColor': this.colors.brandGrayColor,
            '--arfont': this.colors.arfont,
            '--enfont': this.colors.enfont,

            
           
        }
    }

  }
  

};
</script>

<style lang="scss">
    @import './src/assets/main.scss';
    header{
          border-bottom: 1px solid #f9f9f9 !important;
    box-shadow: 0 0 16px 0 rgb(216, 216, 216) !important;
    }
    .v-progress-circular {
      margin: 1rem;
    }

    .ui-preloader {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 9;
      padding: 30%;
      background: var(--brandSecColor);
      // display: none;
    }
    .ui-preloader.change-lang {
      z-index: 10;
    }

    .v-card__text.footer-menu {
      background: var(--brandSecColor);
    }

    .slide-leave-active {
        transition: opacity 1s ease;
        opacity: 0;
        animation: slide-out 1s ease-out forwards;
    }

    .slide-leave {
        opacity: 1;
        transform: translateX(0);
    }

    .slide-enter-active {
        animation: slide-in 1s ease-out forwards;
    }

    @keyframes slide-out {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-30px);
        }
    }

    @keyframes slide-in {
        0% {
            transform: translateX(-30px);
        }
        100% {
            transform: translateX(0);
        }
    }


  //Language convert preLoader >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  @keyframes lds-ellipsis3 {
  0%, 25% {
    left: 32px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    left: 32px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    left: 100px;
  }
  100% {
    left: 168px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes lds-ellipsis3 {
  0%, 25% {
    left: 32px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    left: 32px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    left: 100px;
  }
  100% {
    left: 168px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  25%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  25%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
@keyframes lds-ellipsis {
  0% {
    left: 32px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  25% {
    left: 32px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    left: 100px;
  }
  75% {
    left: 168px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    left: 168px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
@-webkit-keyframes lds-ellipsis {
  0% {
    left: 32px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  25% {
    left: 32px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    left: 100px;
  }
  75% {
    left: 168px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    left: 168px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
.lds-ellipsis {
  position: relative;
      right: 15px;
  margin: auto !important;
}
.lds-ellipsis > div {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
}
.lds-ellipsis div > div {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #f00;
  position: absolute;
  top: 100px;
  left: 32px;
  -webkit-animation: lds-ellipsis 4.1s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
  animation: lds-ellipsis 4.1s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
}
.lds-ellipsis div:nth-child(1) div {
    -webkit-animation: lds-ellipsis2 4.1s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
    animation: lds-ellipsis2 4.1s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
    background: var(--brandPrimColor);
    opacity: .5;

  }
  .lds-ellipsis div:nth-child(2) div {
    -webkit-animation-delay: -2.05s;
    animation-delay: -2.05s;
    background: var(--brandPrimColor);
    opacity: .7;

  }
  .lds-ellipsis div:nth-child(3) div {
    -webkit-animation-delay: -1.025s;
    animation-delay: -1.025s;
    background: var(--brandPrimColor);
    opacity: 1;

  }
  .lds-ellipsis div:nth-child(4) div {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    background: var(--brandPrimColor);
    opacity: .7;
  }
  .lds-ellipsis div:nth-child(5) div {
    -webkit-animation: lds-ellipsis3 4.1s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
    animation: lds-ellipsis3 4.1s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
    background: var(--brandPrimColor);
    opacity: .5;

  }
.lds-ellipsis {
  width: 100px !important;
  height: 100px !important;
  -webkit-transform: translate(-50px, -50px) scale(0.5) translate(50px, 50px);
  transform: translate(-50px, -50px) scale(0.5) translate(50px, 50px);
}
</style>


