export default {
    loading: {
        user: false,
        form: false,
        ui: false,
        lang: false,
        survey: false,
        qLoader: false,
    },
    error: null,
    path: '',
    token: localStorage.getItem('FBidToken') || '',
    authUser: { "status": "", "profile": { "id": '', "email": "", "firstname": "", "lastname": "", "mobile": null, "profile_picture": "", "bio": null, "organization": { "id": '', "name": "" } } },
    completedAssessmentsList: [{
        items: [],
        _meta: {
            totalCount: 0
        },
        _links: []
    }],
    notcompletedAssessmentsList: [{
        items: [],
        _meta: {
            totalCount: 0
        },
        _links: []
    }],
    notstartedAssessmentsList: [{
        items: [],
        _meta: {
            totalCount: 0
        },
        _links: []
    }],
    sectorsList: [{
        id: 1,
        label: 'Sector A',
        children: []

    }],
    assessmentData: { 'jjjjjj': 'mmmmmmm' },
    notificationsData: {},
    dataUserSelected: null,
    locale: '',
    authNavMenuItems: [],
    unAuthNavMenuItems: [],
    errorsList: [],
    colors:{
        "brandPrimColor": "#34495e",
        "brandSecColor": "#f1f8ff",
        "brandHTextColor": "#2c3e50",
        "brandPTextColor": "#34495e",
        "brandBlackColor": "#000",
        "brandGrayColor": "#f5f5f5",
        "arfont": "Tajawal, sans-serif",
        // "enfont":"Roboto, sans-serif"
    },
    orgTheme: {
        "theme_version": '',
        "organization": {
            "id": '',
            "name": "",
            "about": "",
            "logo": "",
            "logo_icon": "",
            "locale": ""
        },
        "colors": {
            "brandPrimColor": "#8e44ad",
            "brandSecColor": "rgb(247, 243, 255)",
            "brandHTextColor": "#2c3e50",
            "brandPTextColor": "#34495e",
            "brandBlackColor": "#000",
            "brandGrayColor": "#f5f5f5",
            "arfont": "Tajawal, sans-serif",
            // "enfont":"Roboto, sans-serif"
        },
        "footer": {
            "links": [
                { title: "Home", href: "http://" }
            ],
            "social_media": {
                "facebook": "",
                "twitter": "",
                "linkedin": "",
                "instagram": ""
            }
        },
        "menu": null,
        "images": null
    },
    // screams: [],
    // selectedScream: {},

};