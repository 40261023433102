<template>
    <v-row class="">

		<div class="col-sm-12 col-md-4 col-lg-3 sideMenu d-sm-none d-none  d-md-flex" >
			<v-card
				class="mx-auto mt-5"
				width="95%"
				height="95%"
			>
				<v-navigation-drawer
				class="brandPrimColor"
				width="100%"
				permanent
				>
				<v-list>
					<v-list-item>
						<v-list-item-content>
							<b class="brandPrimColor listHdr">{{$t('public.profile')}}</b>
						</v-list-item-content>
					</v-list-item>
					<v-list-item link  to="/profile/edit">
						<v-list-item-icon>
							<v-icon small>fas fa-user-edit</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title>{{$t('ProfileEdit.title')}}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<AppLogoutModal></AppLogoutModal>
					
					<br>
					<v-list-item>
						<v-list-item-content>
							<b class="brandPrimColor listHdr">{{$t('public.points')}}</b>
						</v-list-item-content>
					</v-list-item>
					<!-- <v-list-item link>
						<v-list-item-icon>
							<v-icon small>fas fa-hand-holding-usd</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title>{{$t('public.redeemPoints')}}</v-list-item-title>
						</v-list-item-content>
					</v-list-item> -->
					<v-list-item link to="/profile/leaderBoard" >
						<v-list-item-icon>
							<v-icon small>fas fa-chalkboard</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title>{{$t('public.pointsBoard')}}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<br>
					<v-list-item v-if="theme.footer.social_media.length">
						<v-list-item-content>
							<b class="brandPrimColor listHdr">{{$t('public.socilaMedia')}}</b>
						</v-list-item-content>
					</v-list-item>

					<v-list-item  v-if="theme.footer.social_media.length">
						<v-card-text>
							<span v-for="icon in theme.footer.social_media"
								:key="icon.title">
								<v-btn
								:href="icon.href"
								class="mx-1"
								icon
								small
								v-if="icon.href" 
								>
								<v-icon size="24px">fab fa-{{ icon.title }}</v-icon>
								</v-btn>
							</span>
						</v-card-text>
					</v-list-item>

					<br>
					<v-list-item v-if="theme.footer.links.length">
						<v-list-item-content>
							<b class="brandPrimColor listHdr">{{$t('public.relatedLinks')}}</b>
							<!-- <hr> -->
							<v-card-text class=' pt-0 pb-0'  >
								<a
								v-for="(link, index) in theme.footer.links"
								:key="index"
								:color="colors.brandHTextColor"
								>
									<v-btn
									:href="link.href"
									v-if="link.title"
									text
									rounded
									class="my-2"
								>
								{{ link.title }}
								</v-btn>
								</a>
							</v-card-text>
						</v-list-item-content>
					</v-list-item>

					<br>
					<v-list-item>
						<v-list-item-content>
							<v-card-text class=" pt-0 brandPrimColor" v-if="theme.organization.name">
								<b>{{theme.organization.name}}</b>
							</v-card-text>
							<v-card-text class=" pt-0 " v-if="theme.organization.about">
								{{theme.organization.about}}
							</v-card-text>
						</v-list-item-content>
					</v-list-item>
						
				</v-list>

				<!-- <template v-slot:append>
					<div class="pa-2">
					<v-btn block>Logout</v-btn>
					</div>
				</template> -->
				</v-navigation-drawer>
			</v-card>

		</div>

		<div class="col-sm-12 col-md-8 col-lg-9 profile-tab-wrap">
			<div class="userCard" v-if="userCredentials.profile"> 
				<div class="">
					<v-avatar size="90" tile>
						<v-img v-if="userCredentials.profile.profile_picture" class="card-img" :src="userCredentials.profile.profile_picture" :avatar="userCredentials.profile.firstname"></v-img>
						<avatar v-else class="card-img" :username="userCredentials.profile.firstname"></avatar>
					</v-avatar>
					<h1 class="user-name" v-if="userCredentials.profile.firstname">{{userCredentials.profile.firstname}}</h1>
					<p class="scnd-font-color" v-if="userCredentials.profile.position">{{userCredentials.profile.position}}</p>
				</div>
				
								
				<!-- <ul class="profile-options horizontal-list">
					<li><a class="views" href="#"><p>{{$t('public.rank')}}</p><p>#89</p><span class="icon scnd-font-color">{{$t('public.comparedToColleagues')}}</span></a></li>
					<li><a class="likes" href="#"><p>{{$t('public.departmentPoints')}}</p><p>1400</p><span class="icon scnd-font-color">{{$t('public.colleaguesTotalPoints')}}</span></a></li>
					<li><a class="comments" href="#"><p> {{$t('public.points')}}</p><p class="">120</p><div class="icon scnd-font-color">{{$t('public.from')}} 5 {{$t('public.participations')}}</div> </a></li>
				</ul> -->

				<!-- <router-link class="add-button  profile-action-btn notify" to="/profile/edit"><span class="icon  scnd-font-color"><i class="fas fa-pencil-alt"></i></span></router-link> -->
			</div>




			<v-container fluid class="col-lg-10" style="padding:50px 0">
				<v-tabs
				background-color="transparent"
				class=" asessTabs"
				active-class="activeAsessTab"
				mobile-break-point='350'
				grow
				hide-slider
				v-if="completedAssessmentsList.items || notcompletedAssessmentsList.items || notstartedAssessmentsList.items"
				>
					<v-tab class=" asessTab notstartedAssessmentsList">
						<span class="assessments-column-header">
							<h2>{{$t('public.notStarted')}}</h2>
							<span class="mr-2 ml-2">{{notstartedAssessmentsList._meta.totalCount}}</span>
						</span>
					</v-tab>
					<v-tab class=" asessTab notcompletedAssessmentsList">
						<span class="assessments-column-header">
							<h2>{{$t('public.inProgress')}}</h2>
							<span class="mr-2 ml-2">{{notcompletedAssessmentsList._meta.totalCount}}</span>
						</span>
					</v-tab>
					<v-tab class=" asessTab completedAssessmentsList">
						<span class="assessments-column-header">
							<h2>{{$t('public.completed')}}</h2>
							<span class="mr-2 ml-2">{{completedAssessmentsList._meta.totalCount}}</span>
						</span>
					</v-tab>
					
					<v-tab-item class="tabContainer ">
						<div class="assessments-inner-list row " id="1" v-if="notstartedAssessmentsList.items.length">
							<div class="col-sm-12 col-md-4 "  v-for="(item, x ) in notstartedAssessmentsList.items" :key="item.id">
								<div class="assessments-item " >
									<div class="img mb-2">
										<v-img
											height="50px"
											width="50px"
											src="/img/customer-survey.jpeg"
										></v-img>
									</div>
									<div class="content">
										<h3 class="text-limit-one-title brandPrimColor" v-text="item.title"></h3>
									
										<v-divider class="mb-2 mt-2"></v-divider>
										<p> {{$t('Asses.qNum')}} : {{item.qNum}} </p>
										<p v-if="item.expiryDate"> {{$t('Asses.expiryDate')}} : {{item.expiryDate}} </p>
										<p v-else> {{$t('Asses.expDateNotSet')}}</p>

										
										<v-chip-group
											
											v-model="selection"
											active-class="deep-purple accent-4 white--text"
											column
											class="assessment-timer"
											
										>
											<div v-if="item.survey_time_to_pass" class="  time-chip">
												<i class="fas fa-clock"></i>
												{{$t('public.passTime')}} :
												<v-chip color='gray' active-class='gray'>
													{{item.survey_time_to_pass}} {{$t('public.mins')}}
												</v-chip>
											</div>
											<div v-else class="  time-chip">
												<i class="fas fa-clock"></i>
												<v-chip color='gray' active-class='gray'>
													{{$t('Asses.timeNotSet')}}
												</v-chip>
											</div>
										</v-chip-group>
										
										<v-progress-linear
											v-if="item.remaining_time"
											height="15"
											striped
											:value="(item.remaining_time / item.survey_time_to_pass)*100"
											:title="$t('public.time') + ' ' + $t('public.remaining') + ' : ' + item.remaining_time + ' ' + $t('public.mins')"
											color="#ffc107"
											>
											{{item.remaining_time}} {{$t('public.mins')}}<i class="fas fa-hourglass-half  mr-1 ml-1" style="font-size:10px;"></i>
										</v-progress-linear>
										<v-progress-linear
											height="15"
											class="leftOne"
											striped
											:value="item.progress"
											:title="$t('Asses.progress') + ' : ' + item.progress +'%'"
											:color="item.progress >= 50 ? 'green' : 'orange'"
											>
												{{Math.ceil(item.progress)}}% <i class="fas fa-tasks mr-1 ml-1" style="font-size:10px;"></i>
										</v-progress-linear>
									</div>
									
									<v-btn class="mt-5 primBtn brand" width="100%"  @click="toAssessmentEdit(item.id)" v-if="item.status==0 && !item.isClosed">
										{{$t('public.start')}}
									</v-btn>
									
									<v-btn v-if="item.isClosed && item.status!=2" width="100%" class=" mt-5 primBtn brand" disabled style="color:black;">
										{{$t('Asses.closedForNow')}}
									</v-btn>
								</div>
							</div>
							<div class=" col-12">
								<div class="paging" v-if="notstartedAssessmentsList._meta.pageCount>1">
									<v-btn class="btn v-btn--disabled" id="assessmentsbtnPrev" @click="prevPage(notstartedAssessmentsList, 'assessments', 'SET_NOTSTARTED_ASSESSMENTS_LIST')">
										<v-icon>{{svg.Rchev}}</v-icon>
									</v-btn>
									<span>{{notstartedAssessmentsList._meta.currentPage}}</span>
									<v-btn class="btn" id="assessmentsbtnNext" @click="nextPage(notstartedAssessmentsList, 'assessments', 'SET_NOTSTARTED_ASSESSMENTS_LIST')">
										<v-icon>{{svg.Lchev}}</v-icon>
									</v-btn>
									
								</div>
							</div>
						</div>
						<div class=" assessNotExist mt-5 "  v-else>
							<i class="fas fa-poll mb-4"></i>
							<div>
								لا توجد استبيانات جديدة
							</div>
						</div>
					</v-tab-item>
					<v-tab-item class="tabContainer ">
						<div class="assessments-inner-list row" id="2" v-if="notcompletedAssessmentsList.items">
							<div class="col-sm-12 col-md-4 "  v-for="(item, x ) in notcompletedAssessmentsList.items" :key="item.id">
								<div class="assessments-item " >
									<div class="img mb-2">
										<v-img
											height="50px"
											width="50px"
											src="/img/customer-survey.jpeg"
										></v-img>
									</div>
									<div class="content">
										<h3 class="text-limit-one-title brandPrimColor" v-text="item.title"></h3>
									
										<v-divider class="mb-2 mt-2"></v-divider>
										<p> {{$t('Asses.qNum')}} : {{item.qNum}} </p>
										<p v-if="item.expiryDate"> {{$t('Asses.expiryDate')}} : {{item.expiryDate}} </p>
										<p v-else> {{$t('Asses.expDateNotSet')}}</p>
										
										<v-chip-group
											
											v-model="selection"
											active-class="deep-purple accent-4 white--text"
											column
											class="assessment-timer"
											
										>
											<div v-if="item.survey_time_to_pass" class="  time-chip">
												<i class="fas fa-clock"></i>
												{{$t('public.passTime')}} :
												<v-chip color='gray' active-class='gray'>
													{{item.survey_time_to_pass}} {{$t('public.mins')}}
												</v-chip>
											</div>
											<div v-else class="  time-chip">
												<i class="fas fa-clock"></i>
												<v-chip color='gray' active-class='gray'>
													{{$t('Asses.timeNotSet')}}
												</v-chip>
											</div>
										</v-chip-group>
										
										<v-progress-linear
											v-if="item.remaining_time"
											height="15"
											striped
											:value="(item.remaining_time / item.survey_time_to_pass)*100"
											:title="$t('public.time') + ' ' + $t('public.remaining') + ' : ' + item.remaining_time + ' ' + $t('public.mins')"
											color="#ffc107"
											>
											{{item.remaining_time}} {{$t('public.mins')}} <i class="fas fa-hourglass-half  mr-1 ml-1" style="font-size:10px;"></i>
										</v-progress-linear>
										<v-progress-linear
											height="15"
											class="leftOne"
											striped
											:value="item.progress"
											:title="$t('Asses.progress') + ' : ' + item.progress +'%'"
											:color="item.progress >= 50 ? 'green' : 'orange'"
											>
												{{Math.ceil(item.progress)}}% <i class="fas fa-tasks mr-1 ml-1" style="font-size:10px;"></i>
										</v-progress-linear>
									</div>
									
									
									
									<v-btn v-if="item.isClosed && item.status!=2" width="100%" class=" mt-5 primBtn brand" disabled style="color:black;">
										{{$t('Asses.closedForNow')}}
									</v-btn>

									<v-btn class="mt-5 primBtn orange" width="100%"  @click="toAssessmentEdit(item.id)" v-if="item.status==1 && !item.isClosed">
										{{$t('public.resume')}}
									</v-btn>
								

								</div>
							</div>
							<div class=" col-12">
								<div class="paging" v-if="notcompletedAssessmentsList._meta.pageCount>1">
									<v-btn class="btn v-btn--disabled" id="assessments/not-completebtnPrev" @click="prevPage(notcompletedAssessmentsList, 'assessments/not-complete', 'SET_NOTCOMPLETED_ASSESSMENTS_LIST')">
										<v-icon>{{svg.Rchev}}</v-icon>
									</v-btn>
									<span>{{notcompletedAssessmentsList._meta.currentPage}}</span>
									<v-btn class="btn" id="assessments/not-completebtnNext" @click="nextPage(notcompletedAssessmentsList, 'assessments/not-complete', 'SET_NOTCOMPLETED_ASSESSMENTS_LIST')">
										<v-icon>{{svg.Lchev}}</v-icon>
									</v-btn>
								</div>
							</div>
						</div>
					</v-tab-item>
					<v-tab-item class="tabContainer ">
						<div class="assessments-inner-list row" id="2" v-if="completedAssessmentsList.items">
							<div class="col-sm-12 col-md-4 "  v-for="(item, x ) in completedAssessmentsList.items" :key="item.id">
								<div class="assessments-item " >
									<div class="img mb-2">
										<v-img
											height="50px"
											width="50px"
											src="/img/customer-survey.jpeg"
										></v-img>
									</div>
									<div class="content">
										<h3 class="text-limit-one-title brandPrimColor" v-text="item.title"></h3>
									
										<v-divider class="mb-2 mt-2"></v-divider>
										<p> {{$t('Asses.qNum')}} : {{item.qNum}} </p>
										<p v-if="item.expiryDate"> {{$t('Asses.expiryDate')}} : {{item.expiryDate}} </p>
										<p v-else> {{$t('Asses.expDateNotSet')}}</p>

										
										<v-chip-group
											
											v-model="selection"
											active-class="deep-purple accent-4 white--text"
											column
											class="assessment-timer"
											
										>
											<div v-if="item.survey_time_to_pass" class="  time-chip">
												<i class="fas fa-clock"></i>
												{{$t('public.passTime')}} :
												<v-chip color='gray' active-class='gray'>
													{{item.survey_time_to_pass}} {{$t('public.mins')}}
												</v-chip>
											</div>
											<div v-else class="  time-chip">
												<i class="fas fa-clock"></i>
												<v-chip color='gray' active-class='gray'>
													{{$t('Asses.timeNotSet')}}
												</v-chip>
											</div>
										</v-chip-group>
										
										<v-progress-linear
											v-if="item.survey_time_to_pass"
											height="15"
											striped
											:value="((item.survey_time_to_pass - item.remaining_time) / item.survey_time_to_pass)*100"
											:title="$t('Asses.answerTime') + ' : ' + (item.survey_time_to_pass - item.remaining_time) + ' ' + $t('public.mins')"
											color="#ffc107"
											>
											{{item.survey_time_to_pass - item.remaining_time}} {{$t('public.mins')}} <i class="fas fa-hourglass-half  mr-1 ml-1" style="font-size:10px;"></i>
										</v-progress-linear>
										<v-progress-linear
											height="15"
											class="leftOne"
											striped
											:value="item.progress"
											:title="$t('Asses.progress') + ' : ' + item.progress +'%'"
											:color="item.progress >= 50 ? 'green' : 'orange'"
											>
												{{Math.ceil(item.progress)}}% <i class="fas fa-tasks mr-1 ml-1" style="font-size:10px;"></i>
										</v-progress-linear>
									</div>
									
									
									
									<v-btn v-if="item.isClosed && item.status!=2" width="100%" class=" mt-5 primBtn brand" disabled style="color:black;">
										{{$t('Asses.closedForNow')}}
									</v-btn>

									

									<v-btn class="mt-5 primBtn closed" width="100%"  @click="toAssessmentReport(item.id)" v-if="item.status==2">
										{{$t('public.viewReport')}} 
									</v-btn>
									

								

								</div>
							</div>
							<div class=" col-12">
								<div class="paging" v-if="completedAssessmentsList._meta.pageCount>1">
									<v-btn class="btn v-btn--disabled" id="assessments/completedbtnPrev" @click="prevPage(completedAssessmentsList, 'assessments/completed', 'SET_COMPLETED_ASSESSMENTS_LIST')">
										<v-icon>{{svg.Rchev}}</v-icon>
									</v-btn>
									<span>{{completedAssessmentsList._meta.currentPage}}</span>
									<v-btn class="btn" id="assessments/completedbtnNext" @click="nextPage(completedAssessmentsList, 'assessments/completed', 'SET_COMPLETED_ASSESSMENTS_LIST')">
										<v-icon>{{svg.Lchev}}</v-icon>
									</v-btn>



									

									
									
								</div>
							</div>
						</div>
					</v-tab-item>
					
					
				</v-tabs>
			</v-container>
		</div>
		  


		
    </v-row>
</template>

<script>

	// COMPONENTS
	import AppEditProfile from '@/components/Profile/AppEditProfile.vue';
	import AppLogoutModal from '@/components/AppLogoutModal.vue';
	//Avatar component
	import Avatar from 'vue-avatar'

	// SVG ICONS
	import { mdiBell, mdiMapMarker, mdiWeb, mdiCalendar, mdiPencil, mdiPencilOutline, mdiCameraRetakeOutline ,mdiEmail,mdiPhone,mdiBank,mdiChevronLeft,mdiChevronRight } from '@mdi/js';
	// VUEX
	import { mapGetters } from 'vuex';
	import { log } from 'util';
	import axios from 'axios';
	import Api from '../../service/Api';
	export default {
		components: {
			AppEditProfile,
			 Avatar,
			 AppLogoutModal,
		},
		props: {
			data: {
				type: Object,
			}
		},
		data() {
			return {
				alert:true,
				cardLoading: false,
      			selection: 1,	
				
				assessments: [],
				
				svg: {
					location: mdiMapMarker,
					web: mdiWeb,
					calendar: mdiCalendar,
					pencil: mdiPencil,
					camera: mdiCameraRetakeOutline,
					email:mdiEmail,
					phone:mdiPhone,
					org:mdiBank,
					Lchev:mdiChevronLeft,
					Rchev:mdiChevronRight,
					bellNotify:mdiBell


				}
			}
		},
		methods: {
			toAssessmentEdit (id) {
				this.cardLoading = true
				setTimeout(() => (this.$router.push({ name: 'assessmentEdit', params: {id: id} })), 2000)
			},
			toAssessmentReport (id) {
				this.cardLoading = true
				setTimeout(() => (this.$router.push({ name: 'assessmentReport', params: {id: id} })), 2000)
			},

			handleImageChange(event) {
			// const image = event.target.files[0];
				var f = event.target.files[0]; // FileList object
				var reader = new FileReader();
				var store =this.$store
				// Closure to capture the file information.
				reader.onload = (function(theFile) {
					return function(e) {
					var binaryData = e.target.result;
					//Converting Binary Data to base 64
					var base64String = window.btoa(binaryData);
					//Update the image
					const formData = {
						"image":base64String
					};
					//Upload the image
					store.dispatch('UPLOAD_IMAGE', formData);
					};
				})(f);
				// Read in the image file as a data URL.
				reader.readAsBinaryString(f);
			},
			editImage() {
				const imageInput = document.getElementById('imageInput');
				imageInput.click();
			},
			nextPage(currentList, apiUrl, mutSetter){
				this.cardLoading = true;
				document.getElementById(apiUrl +"btnPrev").classList.remove("v-btn--disabled")
				let vscope=this
				let page = currentList._meta.currentPage+1
				if(currentList._meta.currentPage<currentList._meta.pageCount){
					const config = {
						headers: {
						"Authorization": localStorage.getItem('FBidToken'),
						}
					}
					Api().get('/'+ apiUrl + '?page='+page,config)
					.then((res) => {
						vscope.$store.commit(mutSetter, res.data)
						if(page == currentList._meta.pageCount){
							document.getElementById(apiUrl + "btnNext").classList.add("v-btn--disabled")
						}
						this.cardLoading = false;
					})
				}else{
					
					document.getElementById(apiUrl + "btnNext").classList.add("v-btn--disabled")
					this.cardLoading = false;

				}
				
			},
			prevPage(currentList, apiUrl, mutSetter){
				this.cardLoading = true;

				let vscope=this
				let page = currentList._meta.currentPage-1
				
				if(currentList._meta.currentPage>1){
					const config = {
						headers: {
						"Authorization": localStorage.getItem('FBidToken'),
						}
					}
					Api().get('/'+ apiUrl + '?page='+page,config)
					.then((res) => {
						vscope.$store.commit(mutSetter, res.data)
						document.getElementById(apiUrl + "btnNext").classList.remove("v-btn--disabled")
						if(page == 1){
							document.getElementById(apiUrl + "btnPrev").classList.add("v-btn--disabled")
							//document.getElementById("btnNext").classList.remove("v-btn--disabled")
						}
						this.cardLoading = false;

						
					})
				}else{
					document.getElementById(apiUrl + "btnPrev").classList.add("v-btn--disabled")
					this.cardLoading = false;

				}
			}
		},
		computed: {
			...mapGetters(['colors', 'isAuthenticated', 'userCredentials', 'theme', 'completedAssessmentsList', 'notcompletedAssessmentsList', 'notstartedAssessmentsList']),
			
		},
		mounted(){
			this.$store.dispatch('FETCH_ASSESSMENTS_LIST');
			//console.log(this.notstartedAssessmentsList)

		},

	}





</script>
<style lang="scss" scoped>

$ease-out: all .5s ease-in-out;
$on-hold: #2A92BF;
$in-progress: #FB7D44;
$needs-review: #F4CE46;
$completed: #00B961;

* {
	box-sizing: border-box;
}

a:hover {
	text-decoration: none !important;
}


.profile-tab-wrap {
	// margin: auto;
	background: #e5e5e5;
	text-align: center;
}

.assessments-inner-list.row {
	background: #e5e5e5;

}

.newAvatar {
	    position: relative;
    top: -90px;
    z-index: 2;
}

.userCard {
	background: #fff;
	border-radius: 10px;
	box-shadow: 0 2px 10px 0 rgb(177, 177, 177);
	padding: 10px;
	width: 100%;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.sideMenu {
	background: var(--brandPTextColor);


	.listHdr {
		background: var(--brandSecColor);
		padding: 10px 5px 5px;
	}
}


.assessNotExist {
	padding: 30px 0;
	width: 200px;
	margin: auto;

	i {
		font-size: 50px;
		color: rgb(90, 95, 105);
	}
}





body {
	background: #33363D;
	color: white;
	font-family: 'Lato';
	font-weight: 300;
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
}

ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.assessments-container {
	// max-width: 1000px;
	// margin: 20px auto;
	color: white;

	.section-title {
		color: black;
		padding: 10px;
		font-size: 25px;
		font-weight: 500;
	}
}

.assessments-list {
	display: flex;
	align-items: flex-start;
	
	@media (max-width: 690px) {
		display: block;
	}
}

.assessments-column {
	@media (min-width: 690px) {
		flex: 1;
		margin: 0 10px;
	}
	position: relative;
	background: rgba(black, 0.1);
	overflow: hidden;
	
	@media (max-width: 690px) {
		margin-bottom: 30px;
	}

	.view-more {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: rgb(206, 206, 206);
		color: #1f253d;
		padding: 10px;
		border-bottom-left-radius: 20px;
		border-bottom-right-radius: 20px;
		margin-top: 30px;
		transition: all ease-in-out .2s;
		width: 50%;
		font-size: 12px;
		margin: auto;

		&:hover {
			background: #1a4e95;
			color: white;
			text-decoration: none;
		}
	}
	
	h2 {
		font-size: 0.8rem;
		margin: 0;
		text-transform: uppercase;
		font-weight: 600;
	}
	
	&-on-hold {
		.assessments-column-header,
		.is-moved,
		.assessments-options {
			background: $on-hold;
		}
	}
	
	&-in-progress {
		.assessments-column-header,
		.is-moved,
		.assessments-options {
			background: $in-progress;
		}
	}
	
	&-needs-review {
		.assessments-column-header,
		.is-moved,
		.assessments-options{
			background: $needs-review;
		}
	}
	
	&-completed {
		.assessments-column-header,
		.is-moved,
		.assessments-options {
			background: $completed;
		}
	}
}

.assessments-column-header {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
	position: relative;
    right: -12px;
    width: 106%;
    height: 60px;
    top: -12px;


	h2 {
		font-weight: 100;
		font-size: 16px
	}
}



.assessments-item {
	
	background: rgba(black, 0.2);
	color: #000;
	transition: $ease-out;
    padding: 10px;
	background: #fff;
	position: relative;

	&:hover {
		transform: scale(1.05);
	}

	.img {
		width: 100%;
		text-align: center;
	}

	.v-responsive.v-image {
		// height: 50px;
		// width: 100px;
		border-radius: 50px;
		display: inline-block;
	}

	.title {
        color: var(--brandPrimColor);

	}
	.content {
		p, .assessment-timer {
			margin-bottom: 5px;
			font-size: 12px;
		}

		.v-chip {
			font-size: 12px;
		}
	}

	a {
		height: 100%;
		width: 100%;
		display: inline-block;
		padding: 10px;
		transition: $ease-out;


		&:hover {
			box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.22);
			text-decoration: none;
			
		}
	}
	
	&.is-moving {
		transform: scale(1.5);
		background: rgba(black, 0.8);
	}
	
}

.assessments-header-more {
	cursor: pointer;
}

.assessments-options {
	position: absolute;
	top: 44px;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 10px;
	transform: translateX(100%);
	opacity: 0;
	transition: $ease-out;
	
	&.active {
		transform: translateX(0);
		opacity: 1;
	}
	
	&-label {
		display: block;
		margin: 0 0 5px 0;
		
		input {
			opacity: 0.6;
		}
		
		span {
			display: inline-block;
			font-size: 0.9rem;
			font-weight: 400;
			margin-left: 5px;
		}
	}
}

/* assessmentsula CSS  */

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
	list-style-type: none;
}

.gu-hide {
  display: none !important;
}

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.gu-transit {
  opacity: 0.2;
}

/* Demo info */

.section {
	padding: 20px;
	text-align: center;
	
	a {
		color: white;
		text-decoration: none;
		font-weight: 300;
	}
	
	h4 {
		font-weight: 400;
		a {
			font-weight: 600;
		}
	}
}











.bannerImg {
	position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    height: 250px;
}

.v-window.v-item-group.theme--light.v-tabs-items {
    background-color: red !important;
}

.theme--light.v-tabs-items {
	background-color: #e5e5e5 !important;
}






.profile-card p {
	color: #000;
}

.details{
    h3,p{
        width: 100%;
        display: block;
        text-align: center;
        
    }
    h3{
        font-size: 1.6rem;
        text-transform: uppercase;
        font-weight: 100;
        color: var(--brandPrimColor);
    }
    p{
        .v-icon.v-icon{
            height: 18px;
            width: 18px;
            color: var(--brandPrimColor);
        }
    }
}
.vue-avatar--wrapper{
	    width: 90px !important;
    height: 90px !important;
	font-size: 2.4em !important;
	border-radius: 10px !important;
	span{
		font-size: 2.4em;
	}
}
.no-assessments {
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	color: rgb(136, 136, 136);
	border: 2px dashed #c7c7c7;
    border-radius: 10px;

	i {
		font-size: 40px;
	}

	h2{
		color: rgb(136, 136, 136);
	}
}

.profile-card {
	// height: 1000px;
	// background: #394264;
	margin: auto;

}
.assessment-timer{
	height: 47px;
}
.assessment-timer .time-chip {
    text-align: center;
    width: 100%;
}
 .assessment-list{

	// margin: 50px auto;
	 text-align: center;
	 box-sizing: border-box;
	// border: 2px dashed #c7c7c7;
	// border-radius: 10px;
	padding-top: 0px;
	padding: 30px;
	font-size: 16px;

	.assessment-desc, .assessment-timer .time-chip {
		font-size: 12px;
		width: 100%;
	}
	
	.text-limit-one-desc {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		line-height: 20px;     /* fallback */
		max-height: 20px;      /* fallback */
		-webkit-line-clamp: 1; /* number of lines to show */
		-webkit-box-orient: vertical;
	}

	.text-limit-one-title {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		line-height: 36px;
    	max-height: 36px;
		-webkit-line-clamp: 1; /* number of lines to show */
		padding: 0 16px;
		font-size: 18px;
    // margin: 16px 0;
	}

	 .index {
		position: absolute;
		right: -10px;
		top: -10px;
		background: var(--brandPrimColor);
		line-height: 30px;
		z-index: 5;
		border-radius: 50px;
		height: 30px;
		width: 30px;
		color: #fff;
		border-radius: 0px !important;
		box-shadow: 0 0 10px 0 #000;
	 }

	 .v-card--shaped {
		border-radius: 8px ;
		box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12)
	}
	.v-card--shaped:hover{
		box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	}

	

	 .assessment-card {
		position: relative;
		 .v-card__actions {

			 padding: 0 20px 20px 20px;
			 .v-btn{
				     width: 100%;
					background: #27ae60 !important;
					border: 1px solid #27ae60 !important;
					box-shadow: -1px 4px 7px -4px rgba(0, 0, 0, 0.2);
					border-radius: 50px !important;
					&.orange{
						background: #ff7b00 !important;
						border:1px solid #ff7b00 !important;
					}
					&.closed{
						background: #9E9E9E !important;
						border:1px solid #9E9E9E !important;
					}
					&.brand{
						
						background: var(--brandPrimColor) !important;
						border: 1px solid var(--brandPrimColor) !important;
						
					}
				}
				.v-btn--disabled {
		border: 1px solid #dadada !important;

	}

		 }
	 }

     .assessment {
		 background:  var(--brandGrayColor);
		 padding: 20px;


		 .v-list-item__content {
			 padding: 0;
			 div {
				 margin-bottom: 10px;
			 }

			.v-list-item__title {
				text-align: center;
				span {
					background-color: var(--brandHTextColor);
					color: #fff;
					padding: 10px 20px;
					// text-align: center;
					display: block;
					border-bottom-left-radius: 20px;
					border-bottom-right-radius: 20px;
				}
			 }
			 .v-list-item__subtitle {
				 color: var(--brandPTextColor);
			 }

			 .v-list-item__subtitle.time {
				display: flex;
				justify-content: space-around;
				
				div {
					background-color: white;
					border-radius: 100px;
					padding: 8px;
					font-size: 12px;
				}
			 }

			 @media screen and (max-width: 991px) {
				 .v-list-item__subtitle.time {
					display: block;
					
					div {
						width: auto;
					}
				}
			 }

		 }

		 .v-list-item__action {
			 a {
				min-width: 100px;
				height: 40px;
				font-size: .9rem;
			 }
		 }
     }

     .assessment:hover {
         text-decoration: none;
     }

     .v-progress-circular {
        margin: 0rem !important;
    }

     .edit-assessment {
         color: var(--brandPrimColor);
     }
	 .v-btn{
		 background: var(--brandPrimColor) !important;
		 &:hover{
			 text-decoration: none;
		 }
	 }
        a {
            color: var(--brandPrimColor);
			
            img {
                border: 1px solid #ececec;
                border-radius: 8px;
                padding: 15%;
                width: 60%;
                background: #ececec;
                margin-bottom: 10px;
            }
            p {
                font-size: .9rem;
            }
        }
        
    } 


/** Inspired by: http://graphicburger.com/flat-design-ui-components/ **/
/** Line-chart and donut-chart made by @kseso https://codepen.io/Kseso/pen/phiyL **/


/************************************ FONTS ************************************/
@import url(https://fonts.googleapis.com/css?family=Ubuntu:400,700);
// @import url(http://weloveiconfonts.com/api/?family=entypo|fontawesome|zocial);
/* entypo */
[class*="entypo-"]:before {
  font-family: 'entypo', sans-serif;
}
/* fontawesome */
[class*="fontawesome-"]:before {
  font-family: 'FontAwesome', sans-serif;
}
/* zocial */
// [class*="zocial-"]:before {
//   font-family: 'zocial', sans-serif;
// }
@font-face {
	font-family: 'icomoon';
	src:url('https://jlalovi-cv.herokuapp.com/font/icomoon.eot');
	src:url('https://jlalovi-cv.herokuapp.com/font/icomoon.eot?#iefix') format('embedded-opentype'),
		url('https://jlalovi-cv.herokuapp.com/font/icomoon.ttf') format('truetype'),
		url('https://jlalovi-cv.herokuapp.com/font/icomoon.woff') format('woff'),
		url('https://jlalovi-cv.herokuapp.com/font/icomoon.svg#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.icon-cloudy:before {
	content: "\e60f";
}
.icon-sun:before {
	content: "\e610";
}
.icon-cloudy2:before {
	content: "\e611";
}
/************************************* END FONTS *************************************/

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;  
}

body {
	background: #1f253d;
}

ul {
	list-style-type: none;
	margin: 0;
	padding-left: 0;
}

h1 {
	font-size: 23px;
}

h2 {
	font-size: 17px;
}

p {
	font-size: 15px;
}

a {
	text-decoration: none;
	font-size: 15px;
}
	a:hover {
		text-decoration: underline;
	}


	.scnd-font-color {
		color: #9099b7;
	}


.horizontal-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
	.horizontal-list li {
		float: left;
	}

.clear {
	clear: both;
}

.icon {
	font-size: 25px;
}

.titular {
	display: block;
	line-height: 60px;
	margin: 0;
	text-align: center;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.button {
	display: block;
	width: 175px;
	line-height: 50px;
	font-size: 16px;
	font-weight: 700;
	text-align: center;
	margin: 0 auto;
	border-radius: 5px;
	-webkit-transition: background .3s;
	transition: background .3s;
}
	.button:hover {
		text-decoration: none;
	}

.arrow-btn-container {
	position: relative;
}
	.arrow-btn {
		position: absolute;
		display: block;
		width: 60px;
		height: 60px;
		-webkit-transition: background .3s;
		transition: background .3s;
	}
		.arrow-btn:hover {
			text-decoration: none;
		}
		.arrow-btn.left {
			border-top-left-radius: 5px;
		}
		.arrow-btn.right {
			border-top-right-radius: 5px;
			right: 0;
			top: 0;
		}
		.arrow-btn .icon {
			display: block;
			font-size: 18px;
			border: 2px solid #fff;
			border-radius: 100%;
			line-height: 17px;
			width: 21px;
			margin: 20px auto;
			text-align: center;
		}
			.arrow-btn.left .icon {
				padding-right: 2px;
			}

.profile-picture {
	// border-radius: 100%;
	-webkit-box-sizing: content-box;
  	-moz-box-sizing: content-box;
  	box-sizing: content-box;	
}
	.big-profile-picture {
		margin: 0 auto;		
		// border: 5px solid #50597b;
		width: 90px;
		height: 90px;
	}
	.small-profile-picture {
		border: 2px solid #50597b;
		width: 40px;
		height: 40px;
	}


/** MAIN CONTAINER **/

.main-container {
	font-family: 'Ubuntu', sans-serif;
	width: 950px;

}	

.block {
	margin-bottom: 25px;
    // background: #394264;
    border-radius: 0;
    padding: 0 15px;
}
.profile-action-btn {
	position: absolute;
	top: -20px;
	left: 5px;
}
.profile-action-btn.notify {
	left: 53%;
	top: 50px;
}	

.profile{
	border-radius: 10px !important;
    border: 2px solid #9099b7;
    display: flex;
    align-items: center;
	padding: 10px;
	position: relative;
	background: white;
}
.add-button .icon {
	float: right;
	line-height: 26px;
	width: 30px;
	height: 30px;
	border: 2px solid;
	border-radius: 100%;
	font-size: 12px;
	text-align: center;
		margin: 5px 0 0 0;	
		background: #9099b7;
		color: white;	
transition: $ease-out;		
}
	.add-button .icon:hover {
		background: var(--brandPrimColor);
		border-color: var(--brandPrimColor);
	}
.user-name {
	margin: 20px 0 5px;
	text-align: center;
	font-size: 1.6rem;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 3px;
	color: var(--brandPrimColor); 
	letter-spacing: 0;
}
.profile-description {
	width: 80%;
	margin: 0 auto;
	text-align: center;
	margin-bottom: 20px;
	p{
		margin-bottom: 0;
		font-size: 14px;
	}
}


.profile-options {
	// width: 90%;
	// margin: 0 auto;
	text-align: center;
}
.profile-options li{
	// width: 33%;

	a p {
		font-size: 20px;

		&:hover {
			color: white !important;
		}
	}
}
				
.profile-options p {
	margin: 0;
}
.profile-options a {
	display: block;					
	width: 100%;
	line-height: 60px;
	text-align: center;
	-webkit-transition: background .3s;
	transition: background .3s;				
	background: #50597b;
	color: #ffffff;
	padding: 5px;
	line-height: inherit;
	margin: 0 5px;
}
.profile-options a:hover {
	text-decoration: none;

}
.profile-options a.comments .icon {
	color: #fcb150;
	font-size: 14px;
	display: block;
}
.profile-options a.views .icon {
	color: #06e2e6;
	font-size: 14px;
	display: block;
}
.profile-options a.likes .icon {
	color: #4ce654;
	font-size: 14px;
	display: block;
}
.profile-options .icon {
	// padding-right: 10px;
	// margin-top: -40px
}
.profile-options .comments {
	border-top: 4px solid #fcb150;
	border-right: 1px solid #d4d4d4;
	border-left: 1px solid #d4d4d4;
	// background:  #fcb150;

}
.profile-options .views {
	// background:  #11a8ab;
	border-top: 4px solid #06e2e6;
	border-right: 1px solid #d4d4d4;
	border-left: 1px solid #d4d4d4;
}
.profile-options .likes {
	// background:  #11a8ab;
	border-top: 4px solid #4ce654;
	border-right: 1px solid #d4d4d4;
	border-left: 1px solid #d4d4d4;
}
.weather {
	height: 555px;
}
.weather .titular {
	background: #cc324b;
}
.weather .titular .icon {
	padding-right: 15px;
	font-size: 26px;
}
.weather .current-day {
	height: 135px;
	background: #e64c65;
}
.weather .current-day p {
	margin: 0;
	padding-left: 50px;					
}
.current-day-date {
	font-size: 16px;
	padding-top: 16px;
}
.current-day-temperature {
	font-size: 70px;
}
.current-day-temperature .icon-cloudy {
	padding-left: 20px;
}
				
.weather .next-days p {
	margin: 0;
	display: inline-block;
	font-size: 16px;
}
.weather .next-days a {
	display: block;
	line-height: 58px;
	border-bottom: 1px solid #1f253d;
	-webkit-transition: background .3s;
	transition: background .3s;
}
.weather .next-days a:hover {
	background: #50597b;
}
.weather .next-days a:hover .day {
	color: #e64c65;
}
.weather .next-days-date {
	padding-left: 20px;
}
.weather .next-days-temperature {
	float: right;
	padding-right: 20px;
}
.weather .next-days-temperature .icon {
	padding-left: 10px;
}
.tweets {
	height: 375px;
}
.tweets .titular {
	background: #35aadc;
}
.tweets .titular .icon {
	font-size: 18px;
	padding-right: 20px;
}
.tweet.first {
	height: 150px;
	border-bottom: 1px solid #1f253d;
}
.tweet p:first-child {
	margin: 0;
	padding: 30px 30px 0;
}
.tweet p:last-child {
	margin: 0;
	padding: 15px 30px 0;
}
.tweet-link {
	color: #4fc4f6;
}
.middle-container .social {
	height: 205px;
	background: #1f253d;
}
.middle-container .social li {
	margin-bottom: 12px;
}
.middle-container .social a {
	line-height: 60px;			
}
.middle-container .social a:hover {
	text-decoration: none;
}
.middle-container .social .titular {
	border-radius: 5px;
}
.middle-container .social .facebook {
	background: #3468af;
	-webkit-transition: background .3s;
	transition: background .3s;
}
.middle-container .social a:hover .facebook {
	background: #1a4e95;
}
.middle-container .social a:hover .icon.facebook {
	background: #3468af;
}
.middle-container .social .twitter {
	background: #4fc4f6;
	-webkit-transition: background .3s;
	transition: background .3s;
}
.middle-container .social a:hover .twitter {
	background: #35aadc;
}
.middle-container .social a:hover .icon.twitter {
	background: #4fc4f6;
}
.middle-container .social .googleplus {
	background: #e64c65;
	-webkit-transition: background .3s;
	transition: background .3s;
}
.middle-container .social a:hover .googleplus {
	background: #cc324b;
}
.middle-container .social a:hover .icon.googleplus {
	background: #e64c65;
}
.middle-container .social .icon {
	float: left;
	width: 60px;
	height: 60px;
	text-align: center;
	font-size: 20px;
	border-bottom-left-radius: 5px;
	border-top-left-radius: 5px;
}
.middle-container .social .icon.facebook {
	background: #1a4e95;						
}
.middle-container .social .icon.twitter {
	background: #35aadc;						
}
.middle-container .social .icon.googleplus {
	background: #cc324b;						
}
.paging{
	    display: flex;
    justify-content: space-between;
    align-items: center;
    color:  var(--brandPrimColor);
    font-size: 20px;
	font-weight: 600;
	background: #d8d8d8;
	padding: 10px;
	width: 60%;
	margin: auto;

	button.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
		background-color:  transparent !important;
		
	}
	span {
		background: white;
		width: 40px;
		height: 40px;
		border-radius: 100px;
		line-height: 40px;
		font-weight: 500;

	}

	.btn{
		// color: #fff;
    	// padding: 0 7px;
    	min-width: auto;
		background:  transparent !important;
		box-shadow: none !important;
		margin: 0 2px;
	}
}

.v-application.v-application--is-ltr .paging {
	flex-direction: row-reverse;
}

.v-subheader{
	display: flex;
  justify-content: space-between;
  padding: 0;
    font-size: 1.4em;
    margin-bottom: 15px;
}
.v-application--is-ltr{
	.paging{
		.btn{
			float: right;
		}
	}
	.v-card__text .grey--text{
		text-align: left
	}
}
.my-6{
	margin-top: 0 !important;
}

.v-card__text .grey--text{
	max-height: 40px;
    overflow: hidden;
    line-height: 20px;
    text-align: right;
}
.v-card__text i{
	width: 30px;
}
.v-card__text {
	padding: 5px 16px 5px 0;
}
.v-progress-linear{
	top: 15px;
    max-width: 80px;
    position: absolute;
    border-radius: 20px;
    margin: 0 20px;
    height: 18px !important;
	right: 0;
	font-size: 12px !important;
}


.v-progress-linear.leftOne {
	right: auto;
	left: 0;
}
.v-progress-circular{
	top: 50px;
    position: absolute;
    margin: 0 20px;
	right:25px;
}
.v-progress-linear__content{
	font-size: 12px !important;
}
.v-application .orange{
	 opacity: .8 !important;
}
.v-chip-group .v-chip {
    margin: 4px 8px 4px 0;
    background: #fff !important;
    border: 2px solid #e0e0e0;
}
.v-application .deep-purple.accent-4 {
    background-color: #fff !important;
    border-color: #ff5252 !important;
    color: #ff5252 !important;
}

.v-slide-group__wrapper {

	justify-content: space-between !important;

	div.v-tabs-slider-wrapper {
		display: none !important;
		    height: 0 !important;
	}

	.v-tab.v-tab--active {
		float: right;
	}

}




.asessTab {
	color: rgb(80, 80, 80) !important;
	border-radius: 10px;
	margin: 0 15px;
	height: 45px;
	background: #ccc ;

	padding: 0;
	

	.assessments-column-header {
		position: initial;

		span {
			background: white;
			border-radius: 100%;
			width: 20px;
			height: 20px;
			color: #000;
			line-height: 25px;
		}
	}

	// &.completedAssessmentsList {
	// 	color: #62bb61;
	// }

	// &.notcompletedAssessmentsList {
	// 	color: #f27b43;
	// }

	// &.notstartedAssessmentsList {
	// 	color: #2a92bf;
	// }

	&.activeAsessTab {
		color: #fff !important;
		// border-right:3px solid var(--brandPrimColor);


		.assessments-column-header {
			position: relative !important;
			right: 0 !important;	
			top: 0;
			justify-content: start !important;
		}

		&.completedAssessmentsList {
			// border-right:5px solid #62bb61;
			background: #62bb61;

		}

		&.notcompletedAssessmentsList {
			// border-right:5px solid #f27b43;
			background: #f27b43;
			
		}

		&.notstartedAssessmentsList {
			// border-right:5px solid #2a92bf;
			background: #2a92bf !important;
			
		}
	}
}

.primBtn{
	border: 0 !important;
}
// .primBtn.orange {
//     background-color: #f27b43 !important;
// }
// .primBtn.closed{
// background-color: #62bb61 !important;
// }


.tabContainer .assessments-inner-list {
	padding-top: 40px;
}


</style>